import React from 'react';

import { Content, ErrorMessage, Input, LabelText } from './styles';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

interface InputDefaultProps extends InputProps {
  error?: string;
  label?: string;
}

const InputDefault: React.FC<InputDefaultProps> = React.forwardRef(
  ({ error, label, disabled, ...props }, ref: any) => (
    <Content>
      <LabelText disabled={disabled}>{label}</LabelText>
      <Input ref={ref} error={error} disabled={disabled} {...props} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Content>
  ),
);

export default InputDefault;

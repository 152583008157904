import React from 'react';

const Plus: React.FC<any> = ({ ...props }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3307 6.66634C17.3307 5.92996 16.7338 5.33301 15.9974 5.33301C15.261 5.33301 14.6641 5.92996 14.6641 6.66634L14.6641 14.667L6.66146 14.667C5.92508 14.667 5.32813 15.2639 5.32813 16.0003C5.32813 16.7367 5.92508 17.3337 6.66146 17.3337H14.6641V25.333C14.6641 26.0694 15.261 26.6663 15.9974 26.6663C16.7338 26.6663 17.3307 26.0694 17.3307 25.333V17.3337H25.3281C26.0645 17.3337 26.6615 16.7367 26.6615 16.0003C26.6615 15.2639 26.0645 14.667 25.3281 14.667H17.3307V6.66634Z"
        fill={props?.fill || '#969696'}
      />
    </svg>
  );
};

export { Plus };

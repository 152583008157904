const Transfer: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#CFF6F2" />
      <path
        d="M23.9656 1.03697C23.2497 0.32101 22.0889 0.32101 21.3729 1.03697C20.6569 1.75293 20.6569 2.91373 21.3729 3.6297L23.5762 5.83301H9.33854C7.43782 5.83301 5.61495 6.58806 4.27094 7.93208C2.92693 9.27609 2.17188 11.099 2.17188 12.9997C2.17188 14.0122 2.99269 14.833 4.00521 14.833C5.01773 14.833 5.83854 14.0122 5.83854 12.9997C5.83854 12.0714 6.20729 11.1812 6.86367 10.5248C7.52004 9.86842 8.41028 9.49967 9.33854 9.49967H23.5769L21.3729 11.7036C20.6569 12.4196 20.6569 13.5804 21.3729 14.2964C22.0889 15.0123 23.2497 15.0123 23.9656 14.2964L29.246 9.01603C29.6096 8.68163 29.8385 8.20038 29.8385 7.66634C29.8385 7.12856 29.6064 6.64436 29.2383 6.30969L23.9656 1.03697Z"
        fill="#0D0A0B"
      />
      <path
        d="M8.39573 30.6095C8.91643 31.1302 9.76065 31.1302 10.2814 30.6095C10.802 30.0888 10.802 29.2446 10.2814 28.7239L7.22449 25.667H22.6693C24.4374 25.667 26.1331 24.9646 27.3833 23.7144C28.6336 22.4641 29.3359 20.7684 29.3359 19.0003C29.3359 18.2639 28.739 17.667 28.0026 17.667C27.2662 17.667 26.6693 18.2639 26.6693 19.0003C26.6693 20.0612 26.2478 21.0786 25.4977 21.8288C24.7476 22.5789 23.7301 23.0003 22.6693 23.0003H7.22383L10.2814 19.9428C10.802 19.4221 10.802 18.5779 10.2814 18.0572C9.76065 17.5365 8.91643 17.5365 8.39573 18.0572L3.10742 23.3455C2.8383 23.5895 2.66927 23.9418 2.66927 24.3337C2.66927 24.7287 2.84104 25.0836 3.11396 25.3277L8.39573 30.6095Z"
        fill="#0D0A0B"
      />
    </svg>
  );
};

export { Transfer };

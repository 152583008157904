import React from 'react';

import PC1 from 'assets/images/PC1.png';
import PC2 from 'assets/images/PC2.png';
import PC3 from 'assets/images/PC3.png';
import PC4 from 'assets/images/PC4.png';

import { ButtonDefault } from 'components/Button';
import * as S from './styles';

const ServicesMachines: React.FC = () => {
  const [buttonActive, setButtonActive] = React.useState<number>(1);

  const itemsButton = [
    {
      number: 1,
      title: 'Crie sua cobrança em nossa plataforma web',
      image: PC1,
    },
    {
      number: 2,
      title: 'Defina o valor e os dados da sua cobrança',
      image: PC2,
    },
    {
      number: 3,
      title: 'Gere um link ou Qr Code para o pagamento ',
      image: PC3,
    },
    {
      number: 4,
      title: 'Compartilhe o link com seus clientes e pronto!',
      image: PC4,
    },
  ];

  return (
    <S.Container>
      <S.FirtsFrame>
        <S.FTitle>
          <S.FSpan>Cobre pelos seus serviços sem maquininha!</S.FSpan>
          <br />
          Com Link de pagamento é fácil de cobrar:
        </S.FTitle>

        <S.FImage src={itemsButton[buttonActive - 1]?.image} />

        <S.FContentItems>
          <S.FRowItems>
            {itemsButton
              .filter(item => item.number <= 2)
              .map(item => (
                <S.ButtonItem
                  active={item.number === buttonActive}
                  onClick={() => setButtonActive(item.number)}
                >
                  <S.ButtonNumber>{item.number}</S.ButtonNumber>
                  <S.ButtonText>{item.title}</S.ButtonText>
                </S.ButtonItem>
              ))}
          </S.FRowItems>
          <S.FRowItems>
            {itemsButton
              .filter(item => item.number > 2)
              .map(item => (
                <S.ButtonItem
                  active={item.number === buttonActive}
                  onClick={() => setButtonActive(item.number)}
                >
                  <S.ButtonNumber>{item.number}</S.ButtonNumber>
                  <S.ButtonText>{item.title}</S.ButtonText>
                </S.ButtonItem>
              ))}
          </S.FRowItems>
        </S.FContentItems>

        <ButtonDefault
          color="tertiary"
          className="button-now"
          onClick={() =>
            window.location.replace('https://pro.construpay.com.br/cadastro')
          }
        >
          Comece agora
        </ButtonDefault>
      </S.FirtsFrame>
    </S.Container>
  );
};

export { ServicesMachines };

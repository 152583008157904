const CreditCard: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#CFF6F2" />
      <path
        d="M23 18C22.4477 18 22 18.4477 22 19V20C22 20.5523 22.4477 21 23 21H25C25.5523 21 26 20.5523 26 20V19C26 18.4477 25.5523 18 25 18H23Z"
        fill="#0D0A0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8V24C0 26.2091 1.79086 28 4 28H28C30.2091 28 32 26.2091 32 24V8C32 5.79086 30.2091 4 28 4H4C1.79086 4 0 5.79086 0 8ZM4 6.66667C3.26362 6.66667 2.66667 7.26362 2.66667 8V11H29.3333V8C29.3333 7.26362 28.7364 6.66667 28 6.66667H4ZM28 25.3333C28.7364 25.3333 29.3333 24.7364 29.3333 24V16H2.66667V24C2.66667 24.7364 3.26362 25.3333 4 25.3333H28Z"
        fill="#0D0A0B"
      />
    </svg>
  );
};

export { CreditCard };

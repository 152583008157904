import styled from 'styled-components';

export const Container = styled.button`
  background-color: #ffffff;
  position: relative;

  padding: 23.5px 24px;
  border-radius: 12px;
  margin-top: 16px;
  width: 100%;

  border: 1px solid #e0e0e0;
  cursor: pointer;

  @media (min-width: 1024px) {
    border-radius: 8px;
    margin-top: 24px;
    padding: 32px;
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  text-align: left;
  color: #6e6c6d;
  width: calc(100% - 48px);

  @media (min-width: 1024px) {
    width: calc(100% - 100px);
    font-size: 20px;
    line-height: 24px;
  }

  @media (min-width: 1440px) {
    max-width: 749px;
  }
`;

export const DivIcon = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;
`;

export const RowItem = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ShowText = styled.section`
  margin-top: 24px;
  width: calc(100% - 40px);

  @media (min-width: 1024px) {
    margin-top: 32px;
    width: calc(100% - 96px);
  }
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #969696;
  text-align: left;

  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

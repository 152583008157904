import React from 'react';

import { BankingSolutions } from '../Service/BankingSolutions';
import { ClienteCharge } from './ClienteCharge';
import { ReceiveInstantPayment } from './ReceiveInstantPayment';
import { FinancialAgenda } from './FinancialAgenda';
import { ServicesMachines } from './ServicesMachines';
import { Machines } from '../Service/Machines';

const Story: React.FC = () => {
  return (
    <>
      <BankingSolutions />
      <ClienteCharge />
      <Machines />
      <ReceiveInstantPayment />
      <ServicesMachines />
      <FinancialAgenda />
    </>
  );
};

export { Story };

import styled from 'styled-components';

import ImageClienteCharge from 'assets/images/ImageClienteCharge.png';

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  padding: 40px 24px;
  gap: 48px;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;

    padding: 70px 56px;
  }

  @media (min-width: 1440px) {
    gap: 32px;
    padding: 80px 112px;
    max-width: 1440px;
    margin: 0px auto;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }
`;

export const ImageContent = styled.section`
  width: 100%;
  height: 327px;
  border-radius: 16px;
  position: relative;

  background-image: url(${ImageClienteCharge});
  background-position: center;
  background-size: cover;

  @media (min-width: 1024px) {
    width: 431px;
    height: 403px;
  }

  @media (min-width: 1440px) {
    width: 592px;
    height: 518px;
    border-radius: 24px;
  }
`;

export const ContentText = styled.section`
  display: flex;
  flex-direction: column;

  gap: 16px;

  @media (min-width: 1024px) {
    max-width: 432px;
    margin-bottom: 16px;
  }
  @media (min-width: 1440px) {
    max-width: 592px;
  }
`;

export const Title = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #0d0a0b;

  @media (min-width: 1024px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
  }

  @media (min-width: 1440px) {
    font-size: 48px;
    line-height: 58px;
  }
`;

export const SubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #6e6c6d;

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 29px;
  }

  @media (min-width: 1440px) {
    line-height: 36px;
  }
`;

export const ContentItems = styled.section`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ContentItem = styled.section`
  display: flex;
  align-items: center;

  gap: 16px;
`;

export const Icon = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
`;

export const ItemText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #0d0a0b;

  @media (min-width: 1440px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const LogoAbsolute = styled.section`
  position: absolute;

  bottom: 16px;
  right: 16px;

  svg {
    width: 64px;
    height: 64px;
  }

  @media (min-width: 1024px) {
    svg {
      width: 96px;
      height: 96px;
    }
  }

  @media (min-width: 1440px) {
    bottom: 32px;
    right: 32px;

    svg {
      width: 120px;
      height: 120px;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
  background-color: #ffffff;

  padding: 80px 24px;

  @media (min-width: 1024px) {
    padding: 80px 56px;
  }

  @media (min-width: 1440px) {
    padding: 80px 216px;
    max-width: 1440px;
    margin: 0px auto;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #0d0a0b;

  margin-bottom: 24px;

  @media (min-width: 1024px) {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 29px;
  }

  @media (min-width: 1440px) {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
  }
`;

export const TitleForm = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 80px;

  text-align: center;
  color: #0d0a0b;

  @media (min-width: 1024px) {
    margin-top: 82px;
    font-size: 24px;
    line-height: 29px;
  }

  @media (min-width: 1440px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    margin-top: 80px;
  }
`;

export const SubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  text-align: center;
  color: #0d0a0b;

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 29px;
  }

  @media (min-width: 1440px) {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
  }
`;

export const FormEmail = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e0e0e0;

  padding: 14.5px 24px;
  border-radius: 12px;

  margin: 40px auto 0px;
  max-width: 674px;

  @media (min-width: 1024px) {
    margin-top: 32px;
    padding: 12px 12px 12px 32px;
    border-radius: 16px;
  }

  @media (min-width: 1440px) {
    width: 592px;
    margin-top: 40px;
  }

  button {
    min-height: 35px;
    padding: 0px 16px;

    @media (min-width: 1024px) {
      min-height: 56px;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;

      padding: 0px 32px;
    }
  }
`;

export const TextEmail = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #6e6c6d;

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

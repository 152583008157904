import styled from 'styled-components';

import AutoSizeFImage from 'assets/images/AutoSizeFImage.png';

export const FFContainer = styled.section`
  display: flex;
  background-color: #edf0ef;

  padding: 80px 24px;

  @media (min-width: 1024px) {
    padding: 80px 56px 40px;
    gap: 48px;
  }

  @media (min-width: 1440px) {
    padding: 120px 216px 70px;
    gap: 32px;
    max-width: 1440px;
    margin: 0px auto;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }
`;

export const FContentText = styled.section``;

export const FTitle = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 8px;

  color: #0d0a0b;

  @media (min-width: 1024px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 32px;
    max-width: 310px;
  }

  @media (min-width: 1440px) {
    font-size: 48px;
    line-height: 58px;
    max-width: 100%;
  }
`;

export const FSubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  margin-bottom: 40px;

  color: #6f7974;

  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 32px;
  }

  @media (min-width: 1440px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const FImage = styled.img.attrs({
  alt: '',
  src: AutoSizeFImage,
})`
  display: none;

  @media (min-width: 1024px) {
    display: block;

    width: 432px;
    height: 583px;
  }

  @media (min-width: 1440px) {
    width: 384px;
    height: 600px;
  }
`;

export const FContainerItem = styled.section`
  display: flex;
  flex-direction: column;
  gap: 32px;

  button {
    @media (min-width: 1024px) {
      max-width: 312px;
    }

    @media (max-width: 1023px) {
      margin-top: 8px;
      padding: 0px 32px;
      min-height: 53px;
    }
  }
`;

export const FContentItem = styled.section`
  display: flex;
  gap: 16px;
`;

export const FIcon = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;
`;

export const FText = styled.h4`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #0d0a0b;
`;

export const SecondFrame = styled.section`
  background-color: #edf0ef;
  padding: 40px 24px 80px;

  @media (min-width: 1024px) {
    padding: 80px 56px;
  }

  @media (min-width: 1440px) {
    padding: 70px 216px 140px;
    max-width: 1440px;
    margin: 0px auto;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }
`;

export const SFContainer = styled.section`
  background: #ffffff;
  border: 1px solid #e0e0e0;

  box-shadow: -4px 4px 0px #e0e0e0;
  border-radius: 16px;
  padding: 48px 24px;

  @media (min-width: 1024px) {
    display: flex;
    margin: 0px auto;

    max-width: 672px;
    padding: 48px;

    box-shadow: -8px 8px 0px #e0e0e0;
  }

  @media (min-width: 1440px) {
    max-width: 1008px;
  }
`;

export const SFContentText = styled.section``;

export const SFTitle = styled.h4`
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 16px;

  color: #0d0a0b;

  @media (min-width: 1440px) {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
  }
`;

export const SFSubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #6e6c6d;

  @media (min-width: 1024px) {
    letter-spacing: -0.03em;
    margin-bottom: 32px;
  }

  @media (min-width: 1440px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const SFIcon = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    justify-content: flex-start;
    align-items: flex-start;

    margin-bottom: 0px;
    margin-right: 40px;
  }

  @media (min-width: 1440px) {
    margin-right: 48px;
  }

  svg {
    @media (max-width: 1439px) {
      width: 80px;
      height: 80px;
    }
  }
`;

export const Button = styled.button`
  padding: 8px 16px;

  height: 35px;
  background: #008df1;
  box-shadow: -2px 2px 0px #005591;
  border-radius: 4px;

  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
  border: 0px;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const MaxContent = styled.section`
  background-color: #edf0ef;

  @media (min-width: 1440px) {
    max-width: 1440px;
    margin: 0px auto;
  }
`;

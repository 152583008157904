import PersonFisicPatern1 from 'assets/images/PersonFisicPatern1.png';
import PersonFisicPatern2 from 'assets/images/PersonFisicPatern2.png';
import PersonFisicPatern3 from 'assets/images/PersonFisicPatern3.png';

import * as S from './styles';

const Partners: React.FC = () => {
  const itemsPaterns = [
    {
      title:
        'Seguro Fiança: seguro que substitui com vantagem o papel do fiador',
      description:
        'Vença os encargos de locação e parcele o valor em até 10 vezes sem juros no cartão de crédito. Fale com seu corretor.',
      image: PersonFisicPatern1,
      button: 'Solicite uma proposta',
    },
    {
      title: 'Seguro Moto: segurança sem abrir mão da independência',
      description:
        'Coberturas e benefícios para quem roda todos os dias. Ganhe tempo para curtir sua liberdade com Porto Seguro Moto.',
      image: PersonFisicPatern2,
      button: 'Simule e contrate',
    },
    {
      title:
        'Seguro celular: você fica tranquilo e seu aparelho protegido o tempo todo',
      description:
        'Tenha coberturas conforme a sua rotina. Descubra o plano que mais combina com você. Acesse e faça uma cotação.',
      image: PersonFisicPatern3,
      button: 'Cote agora',
    },
  ];

  return (
    <S.Container>
      <S.Title>
        A construpay tem o parceiro certo para você se sentir mais seguro
      </S.Title>

      <S.ContentGrid>
        {itemsPaterns.map((patern, index) => (
          <S.ContentCard key={patern.title + index}>
            <S.CardImage src={patern.image} />

            <S.CardContentText>
              <S.CardTitle>{patern.title}</S.CardTitle>
              <S.CardDescription>{patern.description}</S.CardDescription>

              <S.ButtonCard color="tertiary">{patern.button}</S.ButtonCard>
            </S.CardContentText>
          </S.ContentCard>
        ))}
      </S.ContentGrid>
    </S.Container>
  );
};

export { Partners };

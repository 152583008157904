import styled from 'styled-components';

import Board from 'assets/images/Board.png';
import NoteBook from 'assets/images/NoteBook.png';
import SFFinancialAgenda from 'assets/images/SFFinancialAgenda.png';

export const Container = styled.section``;

export const FirstFrame = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;

  padding: 40px 0px;
  gap: 48px;
  max-height: 614px;

  @media (min-width: 1024px) {
    padding: 120px 0px 0px;
    max-height: 599px;
  }

  @media (min-width: 1440px) {
    padding-top: 80px;
    max-height: 759px;
    max-width: 1440px;
    margin: 0px auto;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }
`;

export const FContentImage = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  min-height: 314px;
  height: 314px;
  max-height: 314px;

  @media (min-width: 1024px) {
    min-height: 479px;
    height: 479px;
    max-height: 479px;
  }

  @media (min-width: 1440px) {
    min-height: 679px;
    height: 679px;
    max-height: 679px;
  }
`;

export const FFImage = styled.section`
  position: absolute;

  background-image: url(${Board});
  background-position: center;
  background-size: cover;

  bottom: 0px;
  left: 0;
  right: 0;
  height: 160px;
  max-width: 100%;

  @media (min-width: 1024px) {
    height: 212px;
  }

  @media (min-width: 1440px) {
    height: 299.47px;
  }
`;

export const FSImage = styled.section`
  position: relative;
  width: 311px;
  height: 307.38px;

  background-image: url(${NoteBook});
  background-position: center;
  background-size: cover;

  @media (min-width: 1024px) {
    position: absolute;

    width: 485.91px;
    height: 479px;
    right: 0px;
  }

  @media (min-width: 1440px) {
    width: 687px;
    height: 679px;
  }
`;

export const FContentText = styled.section`
  display: flex;
  flex-direction: column;

  padding: 48px 24px 0px;
  gap: 24px;

  @media (min-width: 1024px) {
    max-width: 432px;
    padding: 0px;
    gap: 64px;

    position: absolute;
    left: 58px;
    bottom: 117.5px;
  }

  @media (min-width: 1440px) {
    max-width: 592px;
    left: 112px;

    bottom: 189.76px;
  }

  button {
    height: 61px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    @media (min-width: 1024px) {
      height: 71px;
    }
  }
`;

export const FTitle = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #0d0a0b;

  @media (min-width: 1024px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
  }

  @media (min-width: 1440px) {
    font-weight: 600;
    font-size: 64px;
    line-height: 77px;
  }
`;

export const FSpan = styled.span`
  background-color: #cff6f2;
`;

export const SecondFrame = styled.section`
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 40px 24px;

  @media (min-width: 1024px) {
    flex-direction: row;

    padding: 120px 56px;
  }

  @media (min-width: 1440px) {
    gap: 32px;
    padding: 140px 216px 80px;
    max-width: 1440px;
    margin: 0px auto;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }
`;

export const SFImage = styled.section`
  width: 100%;
  height: 312px;
  position: relative;

  border-radius: 16px;

  background-image: url(${SFFinancialAgenda});
  background-position: center;
  background-size: cover;

  @media (min-width: 1024px) {
    min-width: 314px;
    max-width: 314px;
  }

  @media (min-width: 1440px) {
    min-width: 385px;
    height: 491px;

    border-radius: 24px;
  }
`;

export const SFContentText = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;

  button {
    margin-top: 32px;
    min-height: 56px;

    @media (min-width: 1024px) {
      margin-top: 40px;
      max-width: 351px;
    }

    @media (min-width: 1440px) {
      margin-top: 16px;
    }
  }
`;

export const SFTitle = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #0d0a0b;

  @media (min-width: 1024px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
  }

  @media (min-width: 1440px) {
    font-size: 48px;
    line-height: 58px;
  }
`;

export const SFSubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #0d0a0b;

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 29px;
  }

  @media (min-width: 1440px) {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
  }
`;

export const SFDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 8px;

  color: #6e6c6d;

  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 0px;
  }

  @media (min-width: 1440px) {
    font-size: 24px;
    line-height: 36px;
    margin-top: 16px;
  }
`;

export const LogoAbsolute = styled.section`
  position: absolute;

  bottom: 16px;
  right: 16px;

  svg {
    width: 80px;
    height: 80px;
  }

  @media (min-width: 1440px) {
    bottom: 32px;
    right: 32px;

    svg {
      width: 120px;
      height: 120px;
    }
  }
`;

const EmissionBillet: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#CFF6F2" />
      <path
        d="M9 20C9.55228 20 10 20.4672 10 21.0435V25C10 25.5763 9.55228 26 9 26C8.44772 26 8 25.5763 8 25V21.0435C8 20.4672 8.44772 20 9 20Z"
        fill="#0D0A0B"
      />
      <path
        d="M20 21.0435C20 20.4672 19.5523 20 19 20C18.4477 20 18 20.4672 18 21.0435V25C18 25.5763 18.4477 26 19 26C19.5523 26 20 25.5763 20 25V21.0435Z"
        fill="#0D0A0B"
      />
      <path
        d="M24 21.0435C24 20.4672 23.5523 20 23 20C22.4477 20 22 20.4672 22 21.0435V25C22 25.5763 22.4477 26 23 26C23.5523 26 24 25.5763 24 25V21.0435Z"
        fill="#0D0A0B"
      />
      <path
        d="M12 20.5006C12 20.5006 12 20.0015 11.5 20C11 20 11 20.5006 11 20.5006V25.5C11 25.5 11 26 11.5 26C12 26 12 25.5 12 25.5V20.5006Z"
        fill="#0D0A0B"
      />
      <path
        d="M15.5 20C17 20 17 21 17 21V25C17 25 17 26 15.5 26C14 26 14 25 14 25V21C14 21 14 20 15.5 20Z"
        fill="#0D0A0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.17157 2.50458C5.92172 1.75443 6.93913 1.33301 8 1.33301H18.6667C19.0203 1.33301 19.3594 1.47348 19.6095 1.72353L27.6095 9.72353C27.8595 9.97358 28 10.3127 28 10.6663V26.6663C28 27.7272 27.5786 28.7446 26.8284 29.4948C26.0783 30.2449 25.0609 30.6663 24 30.6663H8C6.93913 30.6663 5.92172 30.2449 5.17157 29.4948C4.42143 28.7446 4 27.7272 4 26.6663V5.33301C4 4.27214 4.42143 3.25473 5.17157 2.50458ZM8 3.99967H17.332V10.6663C17.332 11.4027 17.929 11.9997 18.6654 11.9997H25.3333V26.6663C25.3333 27.02 25.1929 27.3591 24.9428 27.6092C24.6928 27.8592 24.3536 27.9997 24 27.9997H8C7.64638 27.9997 7.30724 27.8592 7.05719 27.6092C6.80714 27.3591 6.66667 27.02 6.66667 26.6663V5.33301C6.66667 4.97939 6.80714 4.64025 7.05719 4.3902C7.30724 4.14015 7.64638 3.99967 8 3.99967ZM19.9987 9.33301H23.4477L19.9987 5.88399V9.33301Z"
        fill="#0D0A0B"
      />
    </svg>
  );
};

export { EmissionBillet };

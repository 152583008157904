const BarCode: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="15" fill="#CFF6F2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 4C28.5523 4 29 4.28352 29 5L29 27C29 27.7165 28.5523 28 28 28C27.4477 28 27 27.7165 27 27L27 5C27 4.28352 27.4477 4 28 4Z"
        fill="#0D0A0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 4C24 4 24 5.28352 24 6V26C24 26.7165 24 28 22 28C20 28 20 26.7165 20 26V6C20 5.28352 20 4 22 4Z"
        fill="#0D0A0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4C6 4 6 5.28352 6 6V26C6 26.7165 6 28 4 28C2 28 2 26.7165 2 26V6C2 5.28352 2 4 4 4Z"
        fill="#0D0A0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 4C17.5523 4 18 4.28352 18 5V27C18 27.7165 17.5523 28 17 28C16.4477 28 16 27.7165 16 27V5C16 4.28352 16.4477 4 17 4Z"
        fill="#0D0A0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4C9.55228 4 10 4.28352 10 5V27C10 27.7165 9.55228 28 9 28C8.44772 28 8 27.7165 8 27V5C8 4.28352 8.44772 4 9 4Z"
        fill="#0D0A0B"
      />
    </svg>
  );
};

export { BarCode };

import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 24px;

  @media (min-width: 1024px) {
    padding: 80px 112px 40px;
  }

  @media (min-width: 1440px) {
    padding: 120px 112px 40px;
    max-width: 1440px;
    margin: 0px auto;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }
`;

export const Title = styled.h4`
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 40px;

  text-align: center;
  color: #0d0a0b;

  @media (min-width: 1024px) {
    margin: 0px auto 40px;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    max-width: 800px;
  }

  br {
    @media (max-width: 1023px) {
      display: none;
    }
  }
`;

export const TitleSpan = styled.span`
  font-weight: 600;
  background-color: #6fe4d8;
`;

export const ListItem = styled.section`
  display: flex;
  flex-direction: column;

  gap: 16px;

  @media (min-width: 1024px) {
    flex-direction: row;
    overflow: scroll;
    gap: 24px;
    padding: 32px;

    border-width: 0px 1px;
    border-style: solid;
    border-color: #e0e0e0;
  }

  @media (min-width: 1440px) {
    gap: 32px;
  }
`;

export const ItemContent = styled.section`
  display: flex;
  flex-direction: row;

  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  gap: 16px;

  @media (min-width: 1024px) {
    max-width: 296px;
    min-width: 296px;
  }
`;

export const ContentText = styled.section`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ItemTItle = styled.h5`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #0d0a0b;
`;

export const ItemSubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;

  color: #6e6c6d;
`;

export const IconContent = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
`;

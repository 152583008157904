import { ButtonDefault } from 'components/Button';
import { LogoNoText } from 'components/Icons';

import * as S from './styles';

const FinancialAgenda: React.FC = () => {
  return (
    <S.Container>
      <S.FirstFrame>
        <S.FContentImage>
          <S.FFImage />
          <S.FSImage />
        </S.FContentImage>

        <S.FContentText>
          <S.FTitle>
            Controle suas vendas e <S.FSpan>antecipe seus recebíveis</S.FSpan>{' '}
            em um só lugar
          </S.FTitle>

          <ButtonDefault
            onClick={() =>
              window.location.replace('https://pro.construpay.com.br/cadastro')
            }
          >
            Conheça a agenda
          </ButtonDefault>
        </S.FContentText>
      </S.FirstFrame>
      <S.SecondFrame>
        <S.SFContainer>
          <S.SFIcon>
            <LogoNoText />
          </S.SFIcon>
          <S.SFContentText>
            <S.SFTitle>
              Consórcio Construpay: sem juros, sem entrada e com parcelas que
              cabem na sua renda
            </S.SFTitle>
            <S.SFSubTitle>
              Taxa de administração a partir de 0,20% ao mês
              <br />
              Crédito a partir de R$ 23 mil
              <br />
              Pagamento em até 240 parcelas
              <br />
              Crédito liberado direto na sua conta corrente Construpay
            </S.SFSubTitle>

            <ButtonDefault color="tertiary">Saiba mais</ButtonDefault>
          </S.SFContentText>
        </S.SFContainer>
      </S.SecondFrame>
    </S.Container>
  );
};

export { FinancialAgenda };

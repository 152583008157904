import styled from 'styled-components';

export const Container = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between:
    background-color: #fff;
    position: relative;
    z-index: 1;
    background-color: #FFF;

    width: 100%;
    padding: 16px;
    height: 64px;

    @media (min-width: 1024px) {
        padding: 24px 72px;
        height: 83px;
    }

    @media(min-width: 1440px){
      width: 1440px;
      margin: 0px auto;
    }
`;

export const Icon = styled.section``;

export const ListLinks = styled.section`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    width: 100%;

    button {
      :last-child {
        margin-left: 32px;
      }
    }
  }
`;

export const LinkMobile = styled.button`
  border: none;
  background-color: transparent;

  width: 32px;
  height: 32px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ButtonLink = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  margin-right: 32px;

  color: #0d0a0b;
  text-decoration: none;
`;

export const RowItems = styled.section`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

import { ButtonDefault } from 'components/Button';
import React from 'react';

import Machine1 from 'assets/images/Machine1.png';
import Machine2 from 'assets/images/Machine2.png';
import Machine3 from 'assets/images/Machine3.png';
import * as S from './styles';

const Machines: React.FC = () => {
  const [slide, setSlide] = React.useState<number>(0);

  const items = [
    {
      title: (
        <S.CardTitle>
          Maquininha <S.CardSpan color="#FFFBCC">Toda Obra</S.CardSpan> mPOS com
          chip com ótimo custo benefício{' '}
        </S.CardTitle>
      ),
      image: Machine1,
      description: 'Maquininha Toda Obra',
    },
    {
      title: (
        <S.CardTitle>
          Maquininha <S.CardSpan color="#CCE8FC">Alto Padrão</S.CardSpan> POS -
          alta tecnologia e desempenho para você
        </S.CardTitle>
      ),
      image: Machine2,
      description: 'Maquininha Alto Padrão',
    },
    {
      title: (
        <S.CardTitle>
          Maquininha <S.CardSpan color="#CFF6F2">TEF</S.CardSpan> POS
          -multiadquirência com total segurança
        </S.CardTitle>
      ),
      image: Machine3,
      description: 'Maquininha TEF',
    },
  ];

  return (
    <S.Container>
      <S.ContentTitle slide={slide}>
        {items.map((item, index) => (
          <S.DivTitle
            key={item.description}
            border={index === 1}
            onClick={() => setSlide(index)}
          >
            <S.Title active={slide === index}>{item.description}</S.Title>
          </S.DivTitle>
        ))}
      </S.ContentTitle>

      <S.CarouselCard
        slideIndex={slide}
        renderAnnounceSlideMessage={({ currentSlide, slideCount }: any) => {
          currentSlide !== slide && setSlide(currentSlide);
          return `Slide ${currentSlide + 1} of ${slideCount}`;
        }}
      >
        {items.map((item, index) => (
          <S.Card key={item.image} reverse={index === 1}>
            <S.ImageCard src={item.image} />

            <S.ContentText>
              {item.title}
              <ButtonDefault color="tertiary">Pedir agora</ButtonDefault>
            </S.ContentText>
          </S.Card>
        ))}
      </S.CarouselCard>
    </S.Container>
  );
};

export { Machines };

import { ButtonDefault } from 'components/Button';
import { LogoNoText } from 'components/Icons';

import * as S from './styles';

const FinancialAgenda: React.FC = () => {
  return (
    <S.Container>
      <S.SecondFrame>
        <S.SFImage>
          <S.LogoAbsolute>
            <LogoNoText />
          </S.LogoAbsolute>
        </S.SFImage>

        <S.SFContentText>
          <S.SFTitle>Empréstimo Consignado</S.SFTitle>
          <S.SFSubTitle>
            Promova o bem-estar financeiro e ajude sua equipe a fugir dos juros
            altos
          </S.SFSubTitle>
          <S.SFDescription>
            Garanta as melhores taxas para seus colaboradores terem uma vida
            financeira mais equilibrada, com mais foco e energia no dia a dia de
            trabalho. Tudo isso com gestão facilitada para o RH e custo zero
            para a empresa.
          </S.SFDescription>

          <ButtonDefault color="tertiary">Conheça o benefício</ButtonDefault>
        </S.SFContentText>
      </S.SecondFrame>

      <S.FirstFrame>
        <S.FContentImage>
          <S.FFImage />
          <S.FSImage />
        </S.FContentImage>

        <S.FContentText>
          <S.FTitle>
            Controle suas vendas e <S.FSpan>antecipe seus recebíveis</S.FSpan>{' '}
            em um só lugar
          </S.FTitle>

          <ButtonDefault
            onClick={() =>
              window.location.replace('https://pro.construpay.com.br/cadastro')
            }
          >
            Conheça a agenda
          </ButtonDefault>
        </S.FContentText>
      </S.FirstFrame>
    </S.Container>
  );
};

export { FinancialAgenda };

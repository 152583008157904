import styled from 'styled-components';

import Board from 'assets/images/Board.png';
import NoteBook from 'assets/images/NoteBook.png';

export const Container = styled.section``;

export const FirstFrame = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;

  padding: 40px 0px;
  gap: 48px;
  max-height: 614px;

  @media (min-width: 1024px) {
    padding: 120px 0px 0px;
    max-height: 599px;
  }

  @media (min-width: 1440px) {
    padding-top: 80px;
    max-height: 759px;
    width: 1440px;
    margin: 0px auto;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }
`;

export const FContentImage = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  min-height: 314px;
  height: 314px;
  max-height: 314px;

  @media (min-width: 1024px) {
    min-height: 479px;
    height: 479px;
    max-height: 479px;
  }

  @media (min-width: 1440px) {
    min-height: 679px;
    height: 679px;
    max-height: 679px;
  }
`;

export const FFImage = styled.section`
  position: absolute;

  background-image: url(${Board});
  background-position: center;
  background-size: cover;

  bottom: 0px;
  left: 0;
  right: 0;
  height: 160px;
  max-width: 100%;

  @media (min-width: 1024px) {
    height: 212px;
  }

  @media (min-width: 1440px) {
    height: 299.47px;
  }
`;

export const FSImage = styled.section`
  position: relative;
  width: 311px;
  height: 307.38px;

  background-image: url(${NoteBook});
  background-position: center;
  background-size: cover;

  @media (min-width: 1024px) {
    position: absolute;

    width: 485.91px;
    height: 479px;
    right: 0px;
  }

  @media (min-width: 1440px) {
    width: 687px;
    height: 679px;
  }
`;

export const FContentText = styled.section`
  display: flex;
  flex-direction: column;

  padding: 48px 24px 0px;
  gap: 24px;

  @media (min-width: 1024px) {
    max-width: 432px;
    padding: 0px;
    gap: 64px;

    position: absolute;
    left: 58px;
    bottom: 117.5px;
  }

  @media (min-width: 1440px) {
    max-width: 592px;
    left: 112px;

    bottom: 189.76px;
  }

  button {
    height: 61px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    @media (min-width: 1024px) {
      height: 71px;
    }
  }
`;

export const FTitle = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #0d0a0b;

  @media (min-width: 1024px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
  }

  @media (min-width: 1440px) {
    font-weight: 600;
    font-size: 64px;
    line-height: 77px;
  }
`;

export const FSpan = styled.span`
  background-color: #cff6f2;
`;

export const SecondFrame = styled.section`
  padding: 40px 24px 80px;

  @media (min-width: 1024px) {
    padding: 80px 56px;
  }

  @media (min-width: 1440px) {
    padding: 70px 216px 140px;
    width: 1440px;
    margin: 0px auto;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }
`;

export const SFContainer = styled.section`
  background: #ffffff;
  border: 1px solid #e0e0e0;

  box-shadow: -4px 4px 0px #e0e0e0;
  border-radius: 16px;
  padding: 48px 24px;

  @media (min-width: 1024px) {
    display: flex;
    margin: 0px auto;

    max-width: 672px;
    padding: 48px;

    box-shadow: -8px 8px 0px #e0e0e0;
  }

  @media (min-width: 1440px) {
    max-width: 1008px;
  }
`;

export const SFContentText = styled.section`
  button {
    height: 53px;
  }

  @media (max-width: 1023px) {
    button {
      display: none;
    }
  }
`;

export const SFTitle = styled.h4`
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #0d0a0b;

  @media (min-width: 1440px) {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
  }
`;

export const SFSubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #6e6c6d;

  @media (min-width: 1024px) {
    letter-spacing: -0.03em;
    margin-bottom: 32px;
  }

  @media (min-width: 1440px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const SFIcon = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    justify-content: flex-start;
    align-items: flex-start;

    margin-bottom: 0px;
    margin-right: 40px;
  }

  @media (min-width: 1440px) {
    margin-right: 48px;
  }

  svg {
    @media (max-width: 1439px) {
      width: 80px;
      height: 80px;
    }
  }
`;

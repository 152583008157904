import React from 'react';

import { Footer } from 'components/Footer';
import { BannerForm } from './BannerForm';
import { Questions } from './Questions';
import { Ratings } from './Ratings';
import { Partners } from './Partners';
import { BannerStory } from './BannerStory';
import { ProdutcsTypes } from './ProdutcsTypes';

const Home: React.FC = () => {
  return (
    <>
      <BannerForm />
      <ProdutcsTypes />

      <Questions />
      {/* <Ratings />
      <Partners /> */}
      <BannerStory />
      <Footer />
    </>
  );
};

export { Home };

import styled from 'styled-components';

export const Container = styled.section``;

export const FirtsFrame = styled.section`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  padding: 40px 24px 80px;
  gap: 64px;

  @media (min-width: 1024px) {
    padding: 80px 56px;
    gap: 32px;
  }

  @media (min-width: 1440px) {
    padding: 80px 220px 140px;
    max-width: 1440px;
    margin: 0px auto;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }

  .button-now {
    padding: 12px 32px;
    height: 53px;

    @media (max-width: 1023px) {
      display: none;
    }
  }
`;

export const FTitle = styled.h3`
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;

  text-align: center;

  color: #0d0a0b;

  @media (min-width: 1024px) {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
  }

  br {
    @media (max-width: 1023px) {
      display: none;
    }
  }
`;

export const FSpan = styled.span`
  font-weight: 700;
  background-color: #cff6f2;
`;

export const FImage = styled.section<{ src: string }>`
  display: none;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;

  @media (min-width: 1024px) {
    display: block;
    width: 480px;
    height: 480px;
  }
`;

export const FContentItems = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 1024px) {
    margin-bottom: 24px;
  }

  @media (min-width: 1440px) {
    flex-direction: row;
    gap: 24px;
    margin-bottom: 0px;
  }
`;

export const FRowItems = styled.section`
  display: flex;
  gap: 15px;

  @media (min-width: 1024px) {
    gap: 48px;
  }

  @media (min-width: 1440px) {
    gap: 24px;
  }
`;

const ButtonStatus = {
  active: {
    border: '#0FD2BE',
    shadow: '-4px 4px 0px #0FD2BE',
    text: '#0D0A0B',
  },
  disable: {
    border: '#CFF6F2',
    shadow: '0px 0px 0px #0FD2BE',
    text: '#969696',
  },
};

interface ButtonItemProps {
  active?: boolean;
}

export const ButtonItem = styled.button<ButtonItemProps>`
  padding: 32px 24px;
  gap: 16px;

  background-color: #fff;
  border: 1px solid #0fd2be;
  box-shadow: -4px 4px 0px #0fd2be;
  border-radius: 24px;

  @media (min-width: 1024px) {
    width: 232px;
    border-color: ${({ active }) =>
      ButtonStatus[active ? 'active' : 'disable'].border};
    box-shadow: ${({ active }) =>
      ButtonStatus[active ? 'active' : 'disable'].shadow};

    h3,
    h6 {
      color: ${({ active }) =>
        ButtonStatus[active ? 'active' : 'disable'].text};
    }
  }
`;

export const ButtonNumber = styled.h3`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: center;

  color: #0d0a0b;

  @media (min-width: 1024px) {
    font-weight: 600;
    font-size: 64px;
    line-height: 77px;
  }
`;

export const ButtonText = styled.h6`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #0d0a0b;

  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

import { Home as HomeLayout } from 'layout';
import GlobalStyles from '../stylesGlobal';

import { Navbar } from '../components/Navbar';
import { Main } from './styles';

const Home = () => {
  return (
    <>
      <GlobalStyles />

      <div>
        <Main>
          <Navbar />
          <HomeLayout />
        </Main>
      </div>
    </>
  );
};
export default Home;

import React from 'react';

import { AnyLess, Plus } from 'components/Icons';
import {
  Container,
  Description,
  DivIcon,
  RowItem,
  ShowText,
  Title,
} from './styles';

export interface QuestionProps {
  quest: any;
}

export interface QuestData {}

const Question: React.FC<QuestionProps> = ({ quest }) => {
  const [show, setShow] = React.useState<boolean>(false);

  return (
    <Container onClick={() => setShow(!show)}>
      <RowItem>
        <Title>{quest.quest}</Title>

        <DivIcon>{show ? <AnyLess /> : <Plus />}</DivIcon>
      </RowItem>

      {show && (
        <ShowText>
          <Description>{quest.answer}</Description>
        </ShowText>
      )}
    </Container>
  );
};

export { Question };

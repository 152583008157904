import React from 'react';

const PersonFisic: React.FC<any> = ({ ...props }) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2279 8.4213C40.5057 7.85957 39.4943 7.85957 38.7721 8.4213L11.7721 29.4213C11.2849 29.8002 11 30.3828 11 31V64C11 66.1217 11.8429 68.1566 13.3431 69.6569C14.8434 71.1571 16.8783 72 19 72H31H49H61C63.1217 72 65.1566 71.1571 66.6569 69.6569C68.1571 68.1566 69 66.1217 69 64V31C69 30.3828 68.7151 29.8002 68.2279 29.4213L41.2279 8.4213ZM51 67C51 67.5523 51.4477 68 52 68H61C62.0609 68 63.0783 67.5786 63.8284 66.8284C64.5786 66.0783 65 65.0609 65 64V32.4673C65 32.1587 64.8575 31.8674 64.6139 31.6779L40.6139 13.0112C40.2528 12.7304 39.7472 12.7304 39.3861 13.0112L15.3861 31.6779C15.1425 31.8674 15 32.1587 15 32.4673V64C15 65.0609 15.4214 66.0783 16.1716 66.8284C16.9217 67.5786 17.9391 68 19 68H28C28.5523 68 29 67.5523 29 67V40C29 38.8954 29.8954 38 31 38H49C50.1046 38 51 38.8954 51 40V67ZM34 68C33.4477 68 33 67.5523 33 67V43C33 42.4477 33.4477 42 34 42H46C46.5523 42 47 42.4477 47 43V67C47 67.5523 46.5523 68 46 68H34Z"
        fill={props?.fill || '#969696'}
      />
    </svg>
  );
};

export { PersonFisic };

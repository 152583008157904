import styled from 'styled-components';

import { ButtonColor } from '.';

const ButtonContent = {
  primary: {
    background: '#feea00',
    color: '#0d0a0b',
    shadow: '#988c00',
    hover: {
      background: '#FEF266',
      color: '#0d0a0b',
      shadow: '#988c00',
    },
  },
  secondary: {
    background: 'transparent',
    color: '#0d0a0b',
    shadow: 'transparent',
    hover: {
      background: '',
      color: '',
      shadow: 'transparent',
    },
  },
  tertiary: {
    background: '#6FE4D8',
    color: '#0d0a0b',
    shadow: '#097E72',
    hover: {
      background: '#CFF6F2',
      color: '#0d0a0b',
      shadow: '#097E72',
    },
  },
};

interface ButtonProps {
  color: ButtonColor;
}

export const Button = styled.button<ButtonProps>`
  cursor: pointer;
  background-color: ${({ color }) => ButtonContent[color].background};
  text-align: center;

  min-height: 56px;

  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  box-shadow: -2px 2px 0px ${({ color }) => ButtonContent[color].shadow};
  border: none;
  border-radius: 4px;

  color: ${({ color }) => ButtonContent[color].color};

  @media (min-width: 1024px) {
    padding: 0px 16px;
    min-height: 35px;
  }

  :hover,
  :focus {
    background-color: ${({ color }) => ButtonContent[color].hover.background};
    box-shadow: -2px 2px 0px ${({ color }) => ButtonContent[color].hover.shadow};
  }

  z-index: 1;
`;

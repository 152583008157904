import React from 'react';

import { ContainerRadio, ContentRadio, TextRadio, RadioCheck } from './styles';

interface Props {
  children: any;
  value: string;
  actualValue: string;
  setValue: any;
}

const Radio: React.FC<Props> = ({ value, actualValue, setValue, children }) => {
  return (
    <ContainerRadio onClick={() => setValue(value)} type="button">
      <ContentRadio active={value === actualValue}>
        <RadioCheck active={value === actualValue} />
      </ContentRadio>
      <TextRadio>{children}</TextRadio>
    </ContainerRadio>
  );
};

export { Radio };

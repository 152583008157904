import React from 'react';

import { Consortium } from './Consortium';
import { Partners } from './Partners';
import { AutoSafe } from './AutoSafe';

const PersonFisic: React.FC = () => {
  return (
    <>
      <Consortium />
      <Partners />
      <AutoSafe />
    </>
  );
};

export { PersonFisic };

import React from 'react';

const PercentageCard: React.FC = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="80" height="80" rx="40" fill="#CFF6F2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 16.6667C38.159 16.6667 36.6667 18.159 36.6667 20C36.6667 21.841 38.159 23.3333 40 23.3333C41.841 23.3333 43.3333 21.841 43.3333 20C43.3333 18.159 41.841 16.6667 40 16.6667ZM30 20C30 14.4772 34.4772 10 40 10C45.5228 10 50 14.4772 50 20C50 25.5228 45.5228 30 40 30C34.4772 30 30 25.5228 30 20Z"
        fill="#0D0A0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3359 40.0003C13.3359 38.1594 14.8283 36.667 16.6693 36.667H63.3359C65.1769 36.667 66.6693 38.1594 66.6693 40.0003C66.6693 41.8413 65.1769 43.3337 63.3359 43.3337H16.6693C14.8283 43.3337 13.3359 41.8413 13.3359 40.0003Z"
        fill="#0D0A0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 56.6667C38.159 56.6667 36.6667 58.159 36.6667 60C36.6667 61.841 38.159 63.3333 40 63.3333C41.841 63.3333 43.3333 61.841 43.3333 60C43.3333 58.159 41.841 56.6667 40 56.6667ZM30 60C30 54.4772 34.4772 50 40 50C45.5228 50 50 54.4772 50 60C50 65.5228 45.5228 70 40 70C34.4772 70 30 65.5228 30 60Z"
        fill="#0D0A0B"
      />
    </svg>
  );
};

export { PercentageCard };

import { Calendar, PaymentCredit, Amount, LogoNoText } from 'components/Icons';

import * as S from './styles';

const ClienteCharge: React.FC = () => {
  const items = [
    {
      text: 'Gerenciamento dos pagamentos recebidos',
      icon: <Amount />,
    },
    {
      text: 'Pague somente quando o seu cliente pagar o boleto',
      icon: <PaymentCredit />,
    },
    {
      text: 'Crie cobranças recorrentes / carnês',
      icon: <Calendar />,
    },
  ];

  return (
    <S.Container>
      <S.ImageContent>
        <S.LogoAbsolute>
          <LogoNoText fill="#ffffff4d" />
        </S.LogoAbsolute>
      </S.ImageContent>

      <S.ContentText>
        <S.Title>
          Cobre seus clientes por boleto bancário de um jeito fácil!
        </S.Title>
        <S.SubTitle>
          Sem burocracia, de modo profissional e automatizado.
        </S.SubTitle>

        <S.ContentItems>
          {items.map(item => (
            <S.ContentItem key={item.text}>
              <S.Icon>{item.icon}</S.Icon>
              <S.ItemText>{item.text}</S.ItemText>
            </S.ContentItem>
          ))}
        </S.ContentItems>
      </S.ContentText>
    </S.Container>
  );
};

export { ClienteCharge };

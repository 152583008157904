import React from 'react';

const Percentage: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#6FE4D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 9.66667C5 7.08934 7.08934 5 9.66667 5C12.244 5 14.3333 7.08934 14.3333 9.66667C14.3333 12.244 12.244 14.3333 9.66667 14.3333C7.08934 14.3333 5 12.244 5 9.66667ZM9.66667 7.66667C8.5621 7.66667 7.66667 8.5621 7.66667 9.66667C7.66667 10.7712 8.5621 11.6667 9.66667 11.6667C10.7712 11.6667 11.6667 10.7712 11.6667 9.66667C11.6667 8.5621 10.7712 7.66667 9.66667 7.66667Z"
        fill="#0D0A0B"
      />
      <path
        d="M24.2748 7.72353C24.7955 8.24423 24.7955 9.08845 24.2748 9.60915L9.60817 24.2758C9.08747 24.7965 8.24325 24.7965 7.72256 24.2758C7.20186 23.7551 7.20186 22.9109 7.72256 22.3902L22.3892 7.72353C22.9099 7.20283 23.7541 7.20283 24.2748 7.72353Z"
        fill="#0D0A0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3346 17.667C19.7573 17.667 17.668 19.7563 17.668 22.3337C17.668 24.911 19.7573 27.0003 22.3346 27.0003C24.912 27.0003 27.0013 24.911 27.0013 22.3337C27.0013 19.7563 24.912 17.667 22.3346 17.667ZM20.3346 22.3337C20.3346 21.2291 21.2301 20.3337 22.3346 20.3337C23.4392 20.3337 24.3346 21.2291 24.3346 22.3337C24.3346 23.4382 23.4392 24.3337 22.3346 24.3337C21.2301 24.3337 20.3346 23.4382 20.3346 22.3337Z"
        fill="#0D0A0B"
      />
    </svg>
  );
};

export { Percentage };

import React from 'react';

import {
  Facebook,
  Instagram,
  LogoFooter,
  Twitter,
  YouTube,
} from 'components/Icons';
import {
  ColumnDesktop,
  Container,
  ContainerLinks,
  ContentLinks,
  ContentSocial,
  DecriptionLanding,
  IconLogo,
  Link,
  RowLogo,
  SectionLink,
  SocialLink,
  Text,
  TextSocial,
  TitleLink,
  WrapSocial,
} from './styles';

const LinksProdutcs = [
  {
    description: 'Maquininhas',
    href: '',
  },
  {
    description: 'TEF',
    href: '',
  },
  {
    description: 'Link de pagamento',
    href: '',
  },
  {
    description: 'Cobrança recorrente',
    href: '',
  },
  {
    description: 'Checkout integrado',
    href: '',
  },
  {
    description: 'Split de pagamento',
    href: '',
  },
  {
    description: 'Pix PDV',
    href: '',
  },
];

const LinksTransparency = [
  {
    description: 'Termos de Uso',
    href: '',
  },
  {
    description: 'Política de Privacidade',
    href: '',
  },
  {
    description: 'Compliance',
    href: '',
  },
  {
    description: 'Contratos',
    href: '',
  },
  {
    description: 'Covid',
    href: '',
  },
];

const LinksOmbudsman = [
  {
    description: '0800 999  3333',
    href: '',
  },
  {
    description: 'ouvidoria@construpay.com.br',
    href: '',
  },
  {
    description: 'Atendimento das 10h ás 16h (dias úteis)',
    href: '',
  },
];

const LinksTalkUs = [
  {
    description: '0800 888 4444',
    href: '',
  },
  {
    description: 'atendimento@construpay.com.br',
    href: '',
  },
  {
    description: 'Atendimento 24h',
    href: '',
  },
  {
    description: '',
    href: '',
  },
  {
    description: '',
    href: '',
  },
];

const socialLinks = [
  {
    icon: <Facebook />,
    href: '',
    type: 'FACEBOOK',
  },
  {
    icon: <Instagram />,
    href: '',
    type: 'INSTAGRAM',
  },
  {
    icon: <YouTube />,
    href: '',
    type: 'YOUTUBE',
  },
  {
    icon: <Twitter />,
    href: '',
    type: 'TWITTER',
  },
];

const Footer: React.FC = () => {
  return (
    <Container>
      <ContainerLinks>
        <RowLogo>
          <IconLogo>
            <LogoFooter />
          </IconLogo>

          <ContentSocial>
            <TextSocial>Siga</TextSocial>

            <WrapSocial>
              {socialLinks.map(social => (
                <SocialLink
                  key={social.type}
                  href={social.href}
                  target="_blank"
                >
                  {social.icon}
                </SocialLink>
              ))}
            </WrapSocial>
          </ContentSocial>
        </RowLogo>

        <ContentLinks>
          <SectionLink>
            <TitleLink>Produtos</TitleLink>
            {LinksProdutcs.map(product => (
              <Link
                key={product.description}
                href={product.href}
                target="_blank"
              >
                {product.description}
              </Link>
            ))}
          </SectionLink>
          <SectionLink id="transparency">
            <TitleLink>Transparência</TitleLink>
            {LinksTransparency.map(transparency => (
              <Link
                key={transparency.description}
                href={transparency.href}
                target="_blank"
              >
                {transparency.description}
              </Link>
            ))}
          </SectionLink>

          <ColumnDesktop>
            <SectionLink>
              <TitleLink>Ouvidoria</TitleLink>
              {LinksOmbudsman.map(ombudsman => (
                <Link
                  key={ombudsman.description}
                  href={ombudsman.href}
                  target="_blank"
                >
                  {ombudsman.description}
                </Link>
              ))}
            </SectionLink>
            <SectionLink>
              <TitleLink>Fale com a gente</TitleLink>
              {LinksTalkUs.map(talkUs => (
                <Link
                  key={talkUs.description}
                  href={talkUs.href}
                  target="_blank"
                >
                  {talkUs.description}
                </Link>
              ))}
            </SectionLink>
          </ColumnDesktop>
        </ContentLinks>
      </ContainerLinks>
      <DecriptionLanding>
        <Text>Copyright © 2023 Construpay. Todos os direitos reservados.</Text>
      </DecriptionLanding>
    </Container>
  );
};

export { Footer };

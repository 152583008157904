import React from 'react';

import { ButtonDefault } from 'components/Button';
import {
  Container,
  ContentText,
  ImageBackground,
  MaxContent,
  Title,
} from './styles';

const BannerStory: React.FC = () => {
  return (
    <Container>
      <MaxContent>
        <ImageBackground />

        <ContentText>
          <Title>
            Do tradicional até a mais recente tecnologia de pagamentos você
            encontra aqui
          </Title>

          <ButtonDefault
            onClick={() =>
              window.location.replace('https://pro.construpay.com.br/cadastro')
            }
          >
            Cadastre-se já
          </ButtonDefault>
        </ContentText>
      </MaxContent>
    </Container>
  );
};

export { BannerStory };

import { BankingSolutions } from './BankingSolutions';
import { FinancialAgenda } from './FinancialAgenda';
import { Machines } from './Machines';
import { ServicesMachines } from './ServicesMachines';

const Service: React.FC = () => {
  return (
    <>
      <BankingSolutions />
      <Machines />
      <ServicesMachines />
      <FinancialAgenda />
    </>
  );
};

export { Service };

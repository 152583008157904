import styled from 'styled-components';

import QrCode from 'assets/images/QrCode.png';

import TFTConsortiumImage from 'assets/images/TFTConsortiumImage.png';
import TFReceiveInstantPayment from 'assets/images/TFReceiveInstantPayment.png';

export const Container = styled.section``;

export const FirstFrame = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 40px 24px 80px;

  @media (min-width: 1024px) {
    padding: 40px 168px 80px;
  }

  @media (min-width: 1440px) {
    padding: 40px 216px 120px;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }
`;

export const FCard = styled.section`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  align-items: center;
  justify-content: center;

  padding: 40px 16px;
  gap: 24px;
  border-radius: 16px;

  border: 1px solid #0fd2be;
  box-shadow: -4px 4px 0px #0fd2be;

  @media (min-width: 1024px) {
    flex-direction: row;

    padding: 24px;
    max-width: 671px;
  }

  @media (min-width: 1440px) {
    align-items: flex-start;
    max-width: 800px;
    padding: 32px;
  }
`;

export const FIcon = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

export const FContentText = styled.section`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

export const FTitle = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #0d0a0b;
`;

export const FSubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;

  color: #6e6c6d;
`;

export const SecondFrame = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #0d0a0b;
  position: relative;

  padding: 80px 24px;
  gap: 48px;

  @media (min-width: 1024px) {
    flex-direction: row;

    padding: 80px 56px;
  }

  @media (min-width: 1440px) {
    padding: 80px 112px;
    width: 1440px;
    margin: 0px auto;
  }
`;

export const MaxContent = styled.section`
  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const SImage = styled.img.attrs({
  src: QrCode,
  alt: '',
})`
  width: 96px;
  height: 96px;

  @media (min-width: 1024px) {
    position: absolute;

    width: 432px;
    height: 432px;
    left: 52.34%;
    right: 5.47%;
    top: calc(50% - 432px / 2 + 126.5px);
  }

  @media (min-width: 1440px) {
    width: 370.12px;
    height: 370.12px;
    left: 65.56%;
    right: 8.74%;
    top: calc(50% - 370.12px / 2 + 70.06px);
  }
`;

export const SContentText = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 1024px) {
    max-width: 432px;
  }

  @media (min-width: 1440px) {
    max-width: 800px;
  }

  button {
    margin-top: 24px;
    min-height: 48px;

    @media (min-width: 1024px) {
      margin-top: 16px;
      min-height: 53px;
      width: 190px;
    }
  }
`;

export const STitle = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #ffffff;

  @media (min-width: 1024px) {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
  }
`;

export const SSpan = styled.span`
  color: #6fe4d8;
`;

export const SSubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #6fe4d8;

  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const ThirdFrame = styled.section`
  position: relative;
  display: flex;
  background-color: #edf0ef;

  padding: 80px 24px;

  @media (min-width: 1024px) {
    padding: 120px 56px;
  }

  @media (min-width: 1440px) {
    padding: 120px 112px;
    width: 1440px;
    margin: 0px auto;
  }
`;

export const TFContentText = styled.section`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    margin-right: 48px;

    button {
      max-width: 216px;
      min-height: 56px;
    }
  }

  @media (min-width: 1440px) {
    margin-right: 32px;
  }

  @media (max-width: 1023px) {
    button {
      padding: 0px 16px;
      min-height: 48px;
    }
  }
`;

export const TFTitle = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 16px;
  color: #0d0a0b;

  @media (min-width: 1024px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
  }

  @media (min-width: 1440px) {
    font-size: 48px;
    line-height: 58px;
  }
`;

export const TFSubTitle = styled.h3`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 24px;

  color: #0d0a0b;

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 32px;
  }

  @media (min-width: 1440px) {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
  }
`;

export const TFDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 40px;

  color: #6f7974;

  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.03em;
    margin-bottom: 32px;
  }

  @media (min-width: 1440px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const TFImage = styled.img.attrs({
  src: TFReceiveInstantPayment,
  alt: '',
})`
  display: none;

  /* background-image: url(${TFReceiveInstantPayment});
  background-position: center;
  background-size: cover; */

  @media (min-width: 1024px) {
    display: flex;
    width: 432px;
    height: 448px;

    border-radius: 24px;
  }

  @media (min-width: 1440px) {
    width: 592px;
    height: 448px;
  }
`;

export const TFTImage = styled.section`
  width: 321px;
  height: 36px;
  margin-top: 24px;

  background-image: url(${TFTConsortiumImage});
  background-position: center;
  background-size: cover;

  @media (min-width: 1024px) {
    margin-top: 32px;
  }
`;

import React from 'react';

import { ButtonDefault } from 'components/Button';
import { QuestData, Question } from './Question';
import {
  Container,
  FormEmail,
  SubTitle,
  TextEmail,
  Title,
  TitleForm,
} from './styles';

const questions: QuestData[] = [
  {
    id: 0,
    quest: 'Lorem Ipsum',
    answer: 'Lorem Ipsum Lorem Ipsum',
  },
];

const Questions: React.FC = () => {
  return (
    <Container id="contact">
      <Title>Perguntas frequentes</Title>

      {questions.map((quest: any) => (
        <Question key={quest.id} quest={quest} />
      ))}

      <TitleForm>Tem alguma dúvida?</TitleForm>
      <SubTitle>Entre em contato pelo 0800 8080 ou envie seu e-mail</SubTitle>
      <FormEmail>
        <TextEmail>@contrupay</TextEmail>
        <ButtonDefault color="tertiary">Enviar</ButtonDefault>
      </FormEmail>
    </Container>
  );
};

export { Questions };

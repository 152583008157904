import styled from 'styled-components';

export const ContainerList = styled.section`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  @media (min-width: 1024px) {
    position: relative;
  }

  @media (min-width: 1440px) {
    max-width: 1440px;
    margin: 0px auto;
  }
`;

interface ItemContentProps {
  active: boolean;
}

export const ItemContent = styled.section<ItemContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;

  width: 100%;
  padding: 12px 0px;

  background-color: ${({ active }) => (active ? '#ffffff' : '#EFEFEF')};
  border-top: ${({ active }) => (active ? 2 : 4)}px solid #e0e0e0;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 24px;

    border-top: 0px solid #e0e0e0;
    border-bottom: ${({ active }) => (active ? 2 : 4)}px solid #e0e0e0;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 32px 40px;

    border-bottom: ${({ active }) => (active ? 1 : 4)}px solid #e0e0e0;
  }
`;

export const ContentText = styled.section``;

export const TitleItem = styled.p<ItemContentProps>`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-top: 4px;

  text-align: center;

  color: ${({ active }) => (active ? '#323232' : '#969696')};

  @media (min-width: 1024px) {
    text-align: left;

    margin-top: 0px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }

  @media (min-width: 1440px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const DescriptionItem = styled.p<ItemContentProps>`
  display: none;

  color: ${({ active }) => (active ? '#323232' : '#969696')};

  @media (min-width: 1024px) {
    display: block;
    text-align: left;

    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    margin: 4px 0px 20px;
  }

  @media (min-width: 1440px) {
    margin: 16px 0px;

    font-size: 16px;
    line-height: 24px;
  }
`;

export const IconSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1024px) {
    margin-right: 20px;
  }

  @media (min-width: 1440px) {
    margin-right: 40px;
  }

  svg {
    width: 32px;
    height: 32px;

    @media (min-width: 1024px) {
      width: 40px;
      height: 40px;
    }

    @media (min-width: 1440px) {
      width: 80px;
      height: 80px;
    }
  }
`;

export const ButtonItem = styled.button<ItemContentProps>`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    padding: 7.5px 16px;
    border: none;

    background-color: ${({ active }) => (active ? '#CFF6F2' : '#6FE4D8')};

    box-shadow: -2px 2px 0px
      ${({ active }) => (active ? 'transparent' : '#097E72')};
    border-radius: 4px;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    text-align: center;
    color: ${({ active }) => (active ? '#0FD2BE' : '#0d0a0b')};
  }
`;

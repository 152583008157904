import React from 'react';

import { ButtonDefault } from '../Button';
import { Logo } from '../Icons';
import {
  ButtonLink,
  Container,
  Icon,
  LinkMobile,
  ListLinks,
  RowItems,
} from './styles';

const Navbar: React.FC = () => {
  return (
    <Container>
      <Icon>
        <Logo />
      </Icon>

      <RowItems>
        <LinkMobile>{/* */}</LinkMobile>
        <ListLinks>
          <ButtonLink href="#">Soluções de pagamento</ButtonLink>
          <ButtonLink href="#contact">Contato</ButtonLink>
          <ButtonDefault
            color="secondary"
            onClick={() =>
              window.location.replace('https://pro.construpay.com.br/login')
            }
          >
            Entrar
          </ButtonDefault>
          <ButtonDefault
            onClick={() =>
              window.location.replace('https://pro.construpay.com.br/cadastro')
            }
          >
            Cadastrar
          </ButtonDefault>
        </ListLinks>
      </RowItems>
    </Container>
  );
};

export { Navbar };

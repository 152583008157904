import styled from 'styled-components';

export const Container = styled.section`
  position: relative;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 40px 56px;
  }

  @media (min-width: 1440px) {
    padding: 56px 112px;
    width: 1440px;
    margin: 0px auto;
  }
`;

export const ImageBackground = styled.section<{ src: string }>`
  position: absolute;
  display: none;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  @media (min-width: 1024px) {
    background-image: url(${({ src }) => src});
    background-position: center;
    background-size: cover;
    display: block;
  }
`;

export const ContentText = styled.section`
  position: relative;
  padding: 40px 24px;

  @media (min-width: 1024px) {
    padding: 0px;
    max-width: 432px;
  }

  @media (min-width: 1440px) {
    max-width: 592px;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 16px;

  color: #323232;

  @media (min-width: 1024px) {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 24px;

    color: #fff;
  }

  @media (min-width: 1440px) {
    font-size: 64px;
    line-height: 77px;
    margin-bottom: 32px;
  }
`;

export const GreenLight = styled.span`
  color: #0fd2be;
`;

export const SubTittle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #6e6c6d;

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 29px;

    color: #ffffff;
  }

  @media (min-width: 1440px) {
    font-size: 32px;
    line-height: 39px;
  }
`;

export const ContentForm = styled.form`
  margin-top: 18px;
  padding: 40px 24px;

  background-color: #efefef;
  position: relative;

  @media (min-width: 1024px) {
    background-color: #ffffff;

    box-shadow: 0px 0px 24px #0d0a0b29;
    border-radius: 16px;
    margin-top: 0px;
    padding: 32px;
    max-width: 600px;
  }

  @media (min-width: 1440px) {
    max-width: 384px;
  }

  label {
    margin-top: 32px;

    :first-child {
      margin-top: 0px;
    }
  }

  button {
    margin-top: 40px;

    @media (min-width: 1024px) {
      margin-top: 24px;
    }

    :last-child {
      width: 100%;
      margin-top: 24px;

      @media (min-width: 1024px) {
        margin-top: 40px;
        min-height: 61px;

        font-size: 24px;
        line-height: 29px;
      }

      @media (min-width: 1440px) {
        margin-top: 24px;
      }
    }
  }
`;

export const MaxContent = styled.section`
  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }
`;

import styled from 'styled-components';

export const ContainerRadio = styled.button`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  border: 0;
  background-color: transparent;
`;

interface ContentRadioProps {
  active: boolean;
}
export const ContentRadio = styled.div<ContentRadioProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  margin-right: 16px;
  border-radius: 100%;

  transition: 0.5s all;
  background-color: #fff;
  border: 1px solid ${props => (props.active ? '#0FD2BE' : '#E0E0E0')};
`;

export const RadioCheck = styled.div<ContentRadioProps>`
  display: flex;
  background-color: ${props => (props.active ? '#0FD2BE' : 'transparent')};
  width: 12px;
  height: 12px;
  border-radius: 32px;
  transition: 0.5s all;
`;

export const TextRadio = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #0d0a0b;
`;

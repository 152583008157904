import React from 'react';

const PaymentCredit: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#6FE4D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8962 10.1242C23.3251 11.7246 22.3753 13.3696 20.7749 13.7984C19.1745 14.2272 17.5295 13.2775 17.1007 11.6771C16.6719 10.0767 17.6216 8.43167 19.222 8.00284C20.8224 7.57401 22.4674 8.52376 22.8962 10.1242ZM20.9644 10.6418C21.1073 11.1753 20.7907 11.7236 20.2573 11.8665C19.7238 12.0095 19.1755 11.6929 19.0325 11.1594C18.8896 10.626 19.2062 10.0776 19.7396 9.93469C20.2731 9.79175 20.8214 10.1083 20.9644 10.6418Z"
        fill="#0D0A0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7198 7.17543C10.1864 7.31837 9.86978 7.86671 10.0127 8.40017L12.6009 18.0594C12.6117 18.0997 12.6248 18.1388 12.6401 18.1765C12.4003 18.2269 12.1622 18.2826 11.9274 18.3424C10.4913 18.7077 9.07864 19.2484 8 19.742V18.9001C8 18.3478 7.55228 17.9001 7 17.9001H3C2.44772 17.9001 2 18.3478 2 18.9001V28.9001C2 29.4524 2.44772 29.9001 3 29.9001H7C7.47099 29.9001 7.86592 29.5745 7.97199 29.1361C15.0607 30.4037 19.388 30.0764 22.3068 28.2857C25.4486 26.3582 26.5691 23.971 25.7474 22.0089C24.9654 20.1418 22.6307 19.3913 20.7153 20.5288C20.4797 20.6687 20.2549 20.804 20.0385 20.9342C19.7343 21.1173 19.4461 21.2907 19.1685 21.4526C19.1868 21.3523 19.1996 21.2504 19.2063 21.147C19.2515 20.4516 19.0157 19.7862 18.5367 19.227C17.9876 18.5858 17.258 18.2309 16.4886 18.053L29.2805 14.6254C29.8139 14.4825 30.1305 13.9342 29.9876 13.4007L27.3994 3.74143C27.2564 3.20796 26.7081 2.89138 26.1746 3.03432L10.7198 7.17543ZM12.2034 8.84846L14.2739 16.5759L27.7969 12.9524L25.7264 5.22499L12.2034 8.84846ZM4 27.9001V19.9001H6V27.9001H4ZM8.00037 27.1086L8.00151 21.9673C8.92802 21.4762 10.6457 20.7321 12.4204 20.2807C13.4848 20.0099 14.5056 19.8616 15.3509 19.9087C16.207 19.9563 16.7307 20.1929 17.0177 20.528C17.1855 20.7239 17.2191 20.8846 17.2105 21.0173C17.2007 21.1687 17.1265 21.3871 16.9065 21.6469C16.4575 22.1771 15.5214 22.7221 14.1847 22.9178C13.6661 22.9938 13.2942 23.4572 13.3322 23.9799C13.3703 24.5026 13.8055 24.9073 14.3296 24.9073C17.3353 24.9073 19.0649 23.8614 21.0594 22.6554C21.281 22.5214 21.5063 22.3852 21.7365 22.2485C22.6872 21.6839 23.6252 22.1191 23.9026 22.7815C24.1403 23.349 24.1067 24.8351 21.261 26.581C18.9679 27.9878 15.205 28.4306 8.00037 27.1086Z"
        fill="#0D0A0B"
      />
    </svg>
  );
};

export { PaymentCredit };

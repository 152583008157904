import styled from 'styled-components';

import FFConsortiumImage from 'assets/images/FFConsortiumImage.png';
import TFConsortiumImage from 'assets/images/TFConsortiumImage.png';
import TFTConsortiumImage from 'assets/images/TFTConsortiumImage.png';

export const Container = styled.section``;

export const FirstFrame = styled.section`
  padding: 80px 24px;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;

    padding: 80px 176px;
  }

  @media (min-width: 1440px) {
    width: 1440px;
    margin: 0px auto;
    padding: 140px 217px 70px 216px;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }
`;

export const ImageFrame = styled.section`
  width: 100%;
  height: 327px;
  border-radius: 16px;
  position: relative;
  margin-bottom: 32px;

  background-image: url(${FFConsortiumImage});
  background-position: center;
  background-size: cover;

  @media (min-width: 600px) {
    display: flex;
    height: 450px;
    max-width: 650px;
    margin: 0px auto 32px;
  }

  @media (min-width: 1024px) {
    min-width: 312px;
    height: 310px;
    margin: 0px;
    margin-bottom: 0px;
    margin-right: 48px;
  }

  @media (min-width: 1440px) {
    min-width: 385px;
    height: 366px;
    margin-right: 32px;
  }
`;

export const FFContentText = styled.section`
  @media (max-width: 1023px) {
    button {
      padding: 0px 16px;
      min-height: 48px;
    }
  }
`;

export const FFTitle = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 16px;

  color: #0d0a0b;

  @media (min-width: 1024px) {
    margin-bottom: 32px;
  }

  @media (min-width: 1440px) {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
  }
`;

export const FFSubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
  letter-spacing: -0.03em;

  color: #6e6c6d;

  @media (min-width: 1440px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const SecondFrame = styled.section`
  padding: 40px 24px 80px;

  @media (min-width: 1024px) {
    padding: 80px 56px;
  }

  @media (min-width: 1440px) {
    padding: 70px 216px 140px;
  }
`;

export const SFContainer = styled.section`
  background: #ffffff;
  border: 1px solid #e0e0e0;

  box-shadow: -4px 4px 0px #e0e0e0;
  border-radius: 16px;
  padding: 48px 24px;
  margin: 0px auto;
  max-width: 600px;

  @media (min-width: 1024px) {
    display: flex;

    max-width: 672px;
    padding: 48px;

    box-shadow: -8px 8px 0px #e0e0e0;
  }

  @media (min-width: 1440px) {
    max-width: 1008px;
  }
`;

export const SFContentText = styled.section`
  button {
    min-height: 56px;
  }

  @media (max-width: 1023px) {
    button {
      display: none;
    }
  }
`;

export const SFTitle = styled(FFTitle)`
  margin-bottom: 16px;
`;

export const SFSubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #6e6c6d;

  @media (min-width: 1024px) {
    letter-spacing: -0.03em;
    margin-bottom: 32px;
  }

  @media (min-width: 1440px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const SFIcon = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    justify-content: flex-start;
    align-items: flex-start;

    margin-bottom: 0px;
    margin-right: 40px;
  }

  @media (min-width: 1440px) {
    margin-right: 48px;
  }

  svg {
    @media (max-width: 1439px) {
      width: 80px;
      height: 80px;
    }
  }
`;

export const ThirdFrame = styled.section`
  display: flex;
  background-color: #edf0ef;

  padding: 80px 24px;

  @media (min-width: 1024px) {
    padding: 120px 56px;
  }

  @media (min-width: 1440px) {
    padding: 120px 112px;
    max-width: 1440px;
    margin: 0px auto;
  }
`;

export const TFContentText = styled.section`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    margin-right: 48px;

    button {
      max-width: 216px;
      min-height: 56px;
    }
  }

  @media (min-width: 1440px) {
    margin-right: 32px;
  }

  @media (max-width: 1023px) {
    button {
      padding: 0px 16px;
      min-height: 48px;
    }
  }
`;

export const TFTitle = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 16px;
  color: #0d0a0b;

  @media (min-width: 1024px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
  }

  @media (min-width: 1440px) {
    font-size: 48px;
    line-height: 58px;
  }
`;

export const TFSubTitle = styled.h3`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 24px;

  color: #0d0a0b;

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 32px;
  }

  @media (min-width: 1440px) {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
  }
`;

export const TFDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 40px;

  color: #6f7974;

  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.03em;
    margin-bottom: 32px;
  }

  @media (min-width: 1440px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const TFImage = styled.section`
  display: none;
  position: relative;

  background-image: url(${TFConsortiumImage});
  background-position: center;
  background-size: cover;

  @media (min-width: 1024px) {
    display: flex;
    min-width: 432px;
    height: 448px;

    border-radius: 24px;
  }

  @media (min-width: 1440px) {
    min-width: 592px;
    height: 448px;
  }
`;

export const TFTImage = styled.section`
  width: 321px;
  height: 36px;
  margin-top: 24px;

  background-image: url(${TFTConsortiumImage});
  background-position: center;
  background-size: cover;

  @media (min-width: 1024px) {
    margin-top: 32px;
  }
`;

export const MaxContent = styled.section`
  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const LogoAbsoluteOne = styled.section`
  position: absolute;

  top: 16px;
  right: 16px;

  svg {
    width: 80px;
    height: 80px;
  }

  @media (min-width: 1024px) {
    top: 24px;
    right: 26px;
  }

  @media (min-width: 1440px) {
    top: 32px;
    right: 32px;

    svg {
      width: 120px;
      height: 120px;
    }
  }
`;

export const LogoAbsoluteTwo = styled.section`
  position: absolute;
  bottom: 24px;
  left: 24px;

  svg {
    width: 120px;
    height: 120px;
  }
`;

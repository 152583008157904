import {
  BarCode,
  CreditCard,
  EmissionBillet,
  Transfer,
} from 'components/Icons';
import * as S from './styles';

const BankingSolutions: React.FC = () => {
  const items = [
    {
      title: 'Conta PJ gratuita',
      description: 'Conta sem custo para você administrar seus recebíveis.',
      icon: <CreditCard />,
    },
    {
      title: 'Emissão de boletos',
      description: 'Emita boletos avulsos ou integrados ao sistema da loja.',
      icon: <EmissionBillet />,
    },
    {
      title: 'TED e Pix',
      description: 'Faça transferências para qualquer banco.',
      icon: <Transfer />,
    },
    {
      title: 'Pague suas contas',
      description: 'Pague os boletos das suas contas e tributos',
      icon: <BarCode />,
    },
  ];

  return (
    <S.Container>
      <S.Title>
        Na Construpay, você encontra <br />
        <S.TitleSpan>soluções bancárias</S.TitleSpan> para o seu negócio
      </S.Title>

      <S.ListItem>
        {items.map(item => (
          <S.ItemContent key={item.title}>
            <S.IconContent>{item.icon}</S.IconContent>
            <S.ContentText>
              <S.ItemTItle>{item.title}</S.ItemTItle>
              <S.ItemSubTitle>{item.description}</S.ItemSubTitle>
            </S.ContentText>
          </S.ItemContent>
        ))}
      </S.ListItem>
    </S.Container>
  );
};

export { BankingSolutions };

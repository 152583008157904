import React from 'react';

import { ButtonDefault } from 'components/Button';
import { LogoNoText } from 'components/Icons';
import * as S from './styles';

const Consortium: React.FC = () => {
  return (
    <S.Container>
      <S.FirstFrame>
        <S.ImageFrame>
          <S.LogoAbsoluteOne>
            <LogoNoText fill="#FFFFFF" />
          </S.LogoAbsoluteOne>
        </S.ImageFrame>

        <S.FFContentText>
          <S.FFTitle>
            Profissional CLT, aqui tem crédito exclusivo para você
          </S.FFTitle>
          <S.FFSubTitle>
            Coloque as contas em dia e fuja dos juros altos com o empréstimo
            consignado da Creditas.
          </S.FFSubTitle>
          <ButtonDefault color="tertiary">Simule grátis</ButtonDefault>
        </S.FFContentText>
      </S.FirstFrame>

      <S.SecondFrame>
        <S.SFContainer>
          <S.SFIcon>
            <LogoNoText />
          </S.SFIcon>
          <S.SFContentText>
            <S.SFTitle>
              Consórcio Construpay: sem juros, sem entrada e com parcelas que
              cabem na sua renda
            </S.SFTitle>
            <S.SFSubTitle>
              Taxa de administração a partir de 0,20% ao mês
              <br />
              Crédito a partir de R$ 23 mil
              <br />
              Pagamento em até 240 parcelas
              <br />
              Crédito liberado direto na sua conta corrente Construpay
            </S.SFSubTitle>

            <ButtonDefault color="tertiary">Saiba mais</ButtonDefault>
          </S.SFContentText>
        </S.SFContainer>
      </S.SecondFrame>

      <S.ThirdFrame>
        <S.MaxContent>
          <S.TFContentText>
            <S.TFTitle>Home Equity</S.TFTitle>
            <S.TFSubTitle>
              O melhor empréstimo com garantia de imóvel do mercado
            </S.TFSubTitle>
            <S.TFDescription>
              Com a Creditas você tem juros a partir de 0,99% ao mês + IPCA, até
              240 meses para pagar e uma consultoria financeira personalizada.
            </S.TFDescription>

            <ButtonDefault color="tertiary">Simule grátis</ButtonDefault>

            <S.TFTImage />
          </S.TFContentText>

          <S.TFImage>
            <S.LogoAbsoluteTwo>
              <LogoNoText fill="#FFFFFF4d" />
            </S.LogoAbsoluteTwo>
          </S.TFImage>
        </S.MaxContent>
      </S.ThirdFrame>
    </S.Container>
  );
};

export { Consortium };

import React from 'react';
import Banner from 'assets/images/Banner.png';

import { useForm } from 'react-hook-form';
import { InputDefault, Radio } from 'components/Form';
import { ButtonDefault } from 'components/Button';
import {
  Container,
  ContentForm,
  ContentText,
  GreenLight,
  ImageBackground,
  MaxContent,
  SubTittle,
  Title,
} from './styles';

const BannerForm: React.FC = () => {
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      name: '',
      mail: '',
      phone: '',
    },
  });

  const [accept, setAccept] = React.useState<boolean>(false);

  const name = watch('name');
  const mail = watch('mail');
  const phone = watch('phone');

  const onSubmit = (data: any) => {
    window.location.replace(
      `https://pro.construpay.com.br/cadastro?name=${data.name}&email=${data.mail}&phone=${data.phone}`,
    );
  };

  return (
    <Container>
      <ImageBackground src={Banner} />
      <ContentText>
        <Title>
          <GreenLight>Construpay</GreenLight> Seu novo parceiro de negócios
        </Title>
        <SubTittle>
          Soluções com foco para cada tipo de cliente do setor construtivo
        </SubTittle>
      </ContentText>

      <ContentForm onSubmit={handleSubmit(onSubmit)}>
        <MaxContent>
          <InputDefault label="Nome" placeholder="Nome" {...register('name')} />

          <InputDefault
            label="Telefone"
            placeholder="DDD + Número"
            {...register('phone')}
          />

          <InputDefault
            label="E-mail"
            placeholder="digiteseu@email.com"
            {...register('mail')}
          />

          <Radio
            actualValue="1"
            value={accept ? '1' : '0'}
            setValue={() => setAccept(!accept)}
          >
            Aceito os termos de uso
          </Radio>

          <ButtonDefault
            disabled={
              name?.length === 0 ||
              phone?.length === 0 ||
              mail?.length === 0 ||
              !accept
            }
            type="submit"
          >
            Continuar
          </ButtonDefault>
        </MaxContent>
      </ContentForm>
    </Container>
  );
};

export { BannerForm };

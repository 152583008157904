import styled from 'styled-components';

export const Container = styled.footer`
  margin-bottom: 79px;

  @media (min-width: 1024px) {
    margin-bottom: 0px;
  }

  @media (min-width: 1440px) {
    width: 1440px;
    margin: 0px auto;
  }
`;

export const ContainerLinks = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #0d0a0b;

  padding: 40px 24px 80px;

  @media (min-width: 1024px) {
    padding: 56px 56px 48px;
  }

  @media (min-width: 1440px) {
    padding: 56px 112px;
  }
`;

export const IconLogo = styled.section`
  @media (max=width: 1023px) {
    svg {
      width: 134px;
      height: 32px;
    }
  }
`;

export const RowLogo = styled.section`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ContentSocial = styled.section`
  display: flex;
  align-items: center;
  margin-top: 37px;

  @media (min-width: 1024px) {
    margin-top: 0px;
  }
`;

export const WrapSocial = styled.section`
  display: flex;
  gap: 32px;
`;

export const SocialLink = styled.a`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
`;

export const TextSocial = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  margin-right: 16px;

  color: #ffffff;

  @media (min-width: 1024px) {
    margin-right: 30px;
  }

  @media (min-width: 1440px) {
    margin-right: 40px;
  }
`;

export const ContentLinks = styled.section`
  margin-top: 94px;

  @media (min-width: 1024px) {
    display: flex;
    margin-top: 56px;
  }

  #transparency {
    @media (min-width: 1024px) {
      margin-left: 102px;
    }

    @media (min-width: 1440px) {
      margin-left: 174px;
    }
  }
`;

export const SectionLink = styled.section<{ first?: boolean }>`
  display: flex;
  flex-direction: column;

  margin-top: ${({ first }) => (first ? 0 : 40)}px;

  @media (min-width: 1024px) {
    margin-top: 0px;
  }
`;

export const TitleLink = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;

  color: #ffffff;

  @media (min-width: 1440px) {
    margin-bottom: 0px;
  }
`;

export const Link = styled.a`
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #0fd2be;
  text-decoration: none;
`;

export const ColumnDesktop = styled.section`
  @media (min-width: 1024px) {
    margin-left: 102px;

    section {
      :last-child {
        margin-top: 48px;
      }
    }
  }

  @media (min-width: 1440px) {
    margin-left: 174px;

    section {
      :last-child {
        margin-top: 56px;
      }
    }
  }
`;

export const DecriptionLanding = styled.section`
  padding: 24px;
  background-color: #032a26;

  @media (min-width: 1024px) {
    padding: 19px 56px;
  }

  @media (min-width: 1440px) {
    padding: 19px 112px;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #097e72;

  @media (min-width: 1024px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

import { Button } from './styles';

export type ButtonColor = 'primary' | 'secondary' | 'tertiary';
interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ButtonColor;
  children: any;
}

const ButtonDefault: React.FC<Props> = ({
  color = 'primary',
  children,
  ...rest
}) => {
  return (
    <Button color={color} {...rest}>
      {children}
    </Button>
  );
};

export { ButtonDefault };

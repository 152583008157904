import React from 'react';

import { PersonFisic, ServiceIco, Story } from 'components/Icons';
import { Story as StoryLayout } from './Story';
import { PersonFisic as PersonFisicLayout } from './PersonFisic';
import { Service as ServiceLayout } from './Service';
import {
  ButtonItem,
  ContainerList,
  ContentText,
  DescriptionItem,
  IconSection,
  ItemContent,
  TitleItem,
} from './styles';

// type ActionType = 'STORY' | 'SERVICE' | 'PERSON_FISIC';

const ProdutcsTypes: React.FC = () => {
  const [actual, setActual] = React.useState<string>('STORY');

  const items = [
    {
      icon: <Story fill={actual === 'STORY' ? '#0FD2BE' : '#969696'} />,
      title: 'Lojistas e escritórios',
      description:
        'Lojas de materiais, construção, escritórios de arquitetura, enge...',
      type: 'STORY',
    },
    {
      icon: <ServiceIco fill={actual === 'SERVICE' ? '#0FD2BE' : '#969696'} />,
      title: 'Prestador de serviço',
      description:
        'Pedreiros, Arquitetos, Marceneiros, Engenheiros, Pintores, Gesseiros...',
      type: 'SERVICE',
    },
    {
      icon: (
        <PersonFisic fill={actual === 'PERSON_FISIC' ? '#0FD2BE' : '#969696'} />
      ),
      title: 'Pessoa Física',
      description:
        'Para quem quer construir, reformar ou mobiliar sua casa e muito mais!',
      type: 'PERSON_FISIC',
    },
  ];

  const isActive = (type: string) => type === actual;

  const Body: any = {
    STORY: <StoryLayout />,
    SERVICE: <ServiceLayout />,
    PERSON_FISIC: <PersonFisicLayout />,
  };

  return (
    <>
      <ContainerList>
        {items.map(item => (
          <ItemContent
            key={item.type}
            active={isActive(item.type)}
            onClick={() => setActual(item.type)}
          >
            <IconSection>{item.icon}</IconSection>

            <ContentText>
              <TitleItem active={isActive(item.type)}>{item.title}</TitleItem>
              <DescriptionItem active={isActive(item.type)}>
                {item.description}
              </DescriptionItem>

              <ButtonItem active={isActive(item.type)}>Ver produtos</ButtonItem>
            </ContentText>
          </ItemContent>
        ))}
      </ContainerList>

      {Body?.[actual]}
    </>
  );
};

export { ProdutcsTypes };

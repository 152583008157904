import styled from 'styled-components';

import Carousel from 'nuka-carousel';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 1024px) {
    padding: 80px 56px;
    gap: 80px;
  }

  @media (min-width: 1440px) {
    padding: 80px 200px;
    max-width: 1440px;
  }

  .slider-control-bottomcenter {
    display: none !important;
  }

  .slider-control-centerleft,
  .slider-control-centerright {
    display: none !important;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
  }

  @media (min-width: 640px) {
    margin: 0px auto;
  }
`;

export const Card = styled.section<{ reverse: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 16px;

  gap: 40px;

  @media (min-width: 1024px) {
    padding: 0px;

    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
    gap: 48px;
  }

  @media (min-width: 1024px) {
    gap: 32px;
  }
`;

export const ImageCard = styled.img.attrs({
  alt: '',
})`
  width: 100%;

  @media (min-width: 1024px) {
    max-width: 305px;
  }

  @media (min-width: 1440px) {
    width: 320px;
  }
`;

export const ContentText = styled.section`
  display: flex;
  flex-direction: column;

  gap: 40px;

  @media (min-width: 1024px) {
    max-width: 559px;
  }

  @media (min-width: 1440px) {
    max-width: 592px;
  }

  button {
    min-height: 48px;

    @media (min-width: 1024px) {
      max-width: 197px;
      min-height: 53px;
    }
  }
`;

export const CardTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #0d0a0b;

  @media (min-width: 1024px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
  }

  @media (min-width: 1440px) {
    font-size: 48px;
    line-height: 58px;
  }
`;

export const CardSpan = styled.span<{ color: string }>`
  background-color: ${({ color }) => color};
`;

export const CarouselCard = styled(Carousel)``;

export const ContentTitle = styled.section<{ slide: number }>`
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  gap: 40px;

  transition: all 0.3s;

  @media (min-width: 1024px) {
    gap: 0px;
  }

  @media (max-width: 1023px) {
    section {
      transform: translateX(
        ${({ slide }) =>
          slide === 0
            ? '110%'
            : slide === 1
            ? 'calc(25% - 40px)'
            : 'calc(-75% - 80px)'}
      );
    }
  }
`;

export const DivTitle = styled.section<{ border: boolean }>`
  display: flex;
  width: 100%;

  @media (min-width: 1024px) {
    justify-content: center;
    border: 0px solid #0d0a0b;

    border-left: 1px;
    border-right: 1px;
  }
`;

export const Title = styled.h3<{ active: boolean }>`
  width: max-content;

  font-weight: ${({ active }) => (active ? 600 : 400)};
  font-size: 12px;
  line-height: 15px;
  color: #0d0a0b;

  ${({ active }) => !active && 'opacity: 0.24;'}

  @media(min-width: 1024px) {
    text-align: center;
    font-size: 24px;
    line-height: 29px;
  }

  @media (min-width: 1440px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

import { PercentageCard } from 'components/Icons';
import { ButtonDefault } from 'components/Button';

import * as S from './styles';

const ReceiveInstantPayment: React.FC = () => {
  return (
    <S.Container>
      <S.FirstFrame>
        <S.FCard>
          <S.FIcon>
            <PercentageCard />
          </S.FIcon>
          <S.FContentText>
            <S.FTitle>
              Split de pagamento rápido e fácil de configurar!
            </S.FTitle>
            <S.FSubTitle>
              Divida o pagamento entre seus parceiros de uma maneira rápida e
              segura. Percentual, comissão ou valor fixo, você escolhe as regras
              de como os pagamentos serão repartidos. Tudo isso de forma simples
              e automática.
            </S.FSubTitle>
          </S.FContentText>
        </S.FCard>
      </S.FirstFrame>

      <S.SecondFrame>
        <S.MaxContent>
          <S.SImage />

          <S.SContentText>
            <S.STitle>
              <S.SSpan>Pix PDV. </S.SSpan>
              Receba por Pix direto na tela do seu caixa!
            </S.STitle>
            <S.SSubTitle>
              Receba por Pix na hora e direto no seu caixa!
              <br />
              Sem a preocupação de verificar se o pagamento caiu na sua conta.
              <br />
              Praticidade ao melhor custo/benefício do mercado!
            </S.SSubTitle>

            <ButtonDefault color="tertiary">Saiba mais</ButtonDefault>
          </S.SContentText>
        </S.MaxContent>
      </S.SecondFrame>

      <S.ThirdFrame>
        <S.MaxContent>
          <S.TFContentText>
            <S.TFTitle>
              O crédito ideal para impulsionar o seu negócio
            </S.TFTitle>
            <S.TFSubTitle>
              Melhore seu fluxo de caixa com um empréstimo para capital de giro
            </S.TFSubTitle>
            <S.TFDescription>
              Use seu imóvel ou carro como garantia e tenha mais tranquilidade,
              com parcelas menores e maior prazo para pagamento. Juros a partir
              de 0,99% ao mês + IPCA e até 240 meses para pagar.
            </S.TFDescription>

            <ButtonDefault color="tertiary">Simule grátis</ButtonDefault>

            <S.TFTImage />
          </S.TFContentText>

          <S.TFImage />
        </S.MaxContent>
      </S.ThirdFrame>
    </S.Container>
  );
};

export { ReceiveInstantPayment };

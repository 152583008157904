import { ButtonDefault } from 'components/Button';
import {
  Amount,
  Calendar,
  LogoNoText,
  PaymentCredit,
  Percentage,
} from 'components/Icons';
import * as S from './styles';

const AutoSafe: React.FC = () => {
  const items = [
    {
      title: 'Parcelas a partir de R$ 151/mês',
      icon: <Amount />,
    },
    {
      title: 'Crédito de R$ 5mil a R$ 150 mil',
      icon: <PaymentCredit />,
    },
    {
      title: 'Até 60 meses para pagar',
      icon: <Calendar />,
    },
    {
      title: 'Juros a partir de 1,49% ao mês',
      icon: <Percentage />,
    },
  ];

  return (
    <>
      <S.MaxContent>
        <S.FFContainer>
          <S.FImage />
          <S.FContentText>
            <S.FTitle>
              Use seu carro para ter as menores taxas de juros
            </S.FTitle>
            <S.FSubTitle>
              Com seu carro como garantia do empréstimo, você tem juros a partir
              de 1,49% ao mês e as melhores condições.
            </S.FSubTitle>

            <S.FContainerItem>
              {items.map(item => (
                <S.FContentItem>
                  <S.FIcon>{item.icon}</S.FIcon>
                  <S.FText>{item.title}</S.FText>
                </S.FContentItem>
              ))}

              <ButtonDefault color="tertiary">Fazer simulação</ButtonDefault>
            </S.FContainerItem>
          </S.FContentText>
        </S.FFContainer>
      </S.MaxContent>

      <S.MaxContent>
        <S.SecondFrame>
          <S.SFContainer>
            <S.SFIcon>
              <LogoNoText />
            </S.SFIcon>
            <S.SFContentText>
              <S.SFTitle>
                O Seguro Auto que vai muito além do cuidado com o seu carro.
              </S.SFTitle>
              <S.SFSubTitle>
                Assistência 24h, serviços para sua casa, guincho com KM
                ilimitada, desconto de até 30% em estacionamentos e muito mais.
              </S.SFSubTitle>

              <S.Button>Simule e contrate</S.Button>
            </S.SFContentText>
          </S.SFContainer>
        </S.SecondFrame>
      </S.MaxContent>
    </>
  );
};

export { AutoSafe };

import React from 'react';

const Calendar: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#6FE4D8" />
      <path
        d="M21.0039 18C21.5562 18 22.0039 18.4477 22.0039 19V20H23.0039C23.5562 20 24.0039 20.4477 24.0039 21C24.0039 21.5523 23.5562 22 23.0039 22H22.0039V23C22.0039 23.5523 21.5562 24 21.0039 24C20.4516 24 20.0039 23.5523 20.0039 23V22H19.0039C18.4516 22 18.0039 21.5523 18.0039 21C18.0039 20.4477 18.4516 20 19.0039 20H20.0039V19C20.0039 18.4477 20.4516 18 21.0039 18Z"
        fill="#0D0A0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3372 1.33301C22.0736 1.33301 22.6706 1.92996 22.6706 2.66634V4H25.3385C27.5477 4 29.3385 5.79086 29.3385 8V26.6667C29.3385 28.8758 27.5477 30.6667 25.3385 30.6667H6.67188C4.46274 30.6667 2.67188 28.8758 2.67188 26.6667V8C2.67188 5.79086 4.46274 4 6.67188 4H9.33594V2.66634C9.33594 1.92996 9.93289 1.33301 10.6693 1.33301C11.4057 1.33301 12.0026 1.92996 12.0026 2.66634V4H20.0039V2.66634C20.0039 1.92996 20.6009 1.33301 21.3372 1.33301ZM26.6719 8V12H5.33854V8C5.33854 7.26362 5.9355 6.66667 6.67188 6.66667H9.33594V7.99967C9.33594 8.73605 9.93289 9.33301 10.6693 9.33301C11.4057 9.33301 12.0026 8.73605 12.0026 7.99967V6.66667H20.0039V7.99967C20.0039 8.73605 20.6009 9.33301 21.3372 9.33301C22.0736 9.33301 22.6706 8.73605 22.6706 7.99967V6.66667H25.3385C26.0749 6.66667 26.6719 7.26362 26.6719 8ZM26.6719 14.6667H5.33854V26.6667C5.33854 27.403 5.9355 28 6.67188 28H25.3385C26.0749 28 26.6719 27.403 26.6719 26.6667V14.6667Z"
        fill="#0D0A0B"
      />
    </svg>
  );
};

export { Calendar };

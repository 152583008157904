import styled from 'styled-components';

const ColorInput = {
  default: {
    color: '#0D0A0B',
    border: '#E0E0E0',
  },
  hover: {
    color: '#0D0A0B',
    border: '#E0E0E0',
  },
  error: {
    color: '#FF2727',
    border: '#FF2727',
  },
};

export const Content = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;

  height: 81px;
`;

export const Input = styled.input<{ error?: string }>`
  width: 100%;
  margin-top: 8px;
  border-radius: 8px;
  height: 54px;
  padding: 12px 16px;

  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  background-color: #ffffff;

  caret-color: ${({ error }) => ColorInput[error ? 'error' : 'default'].color};
  border: 1px solid
    ${({ error }) => ColorInput[error ? 'error' : 'default'].border};
  -webkit-text-fill-color: ${ColorInput.default.color};

  ::-webkit-input-placeholder {
    -webkit-text-fill-color: #969696;
  }

  :focus,
  :hover {
    border-color: transparent;
    box-shadow: 0px 0px 0px 2px
      ${({ error }) => ColorInput[error ? 'error' : 'hover'].border}1a;
  }

  :disabled {
    border-color: #e4e7f4;
    -webkit-text-fill-color: #e4e7f4;

    ::-webkit-input-placeholder {
      -webkit-text-fill-color: #e4e7f4;
    }
  }

  internal-autofill-selected {
    appearance: none;
    background-image: none !important;
    background-color: none !important;
    color: fieldtext !important;
  }
`;

export const ErrorMessage = styled.p`
  position: absolute;

  bottom: 0px;
  right: 0px;
  line-height: 16px;
  font-size: 12px;

  font-weight: 400;
  color: #ff2727;
`;

export const LabelText = styled.p<{ disabled?: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: ${({ disabled }) => (disabled ? '#B4B7D0' : '#0D0A0B')};
`;

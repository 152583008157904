import React from 'react';

const AnyLess: React.FC<any> = ({ ...props }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 15.9997C27 16.7361 26.403 17.333 25.6667 17.333L7 17.333C6.26362 17.333 5.66667 16.7361 5.66667 15.9997C5.66667 15.2633 6.26362 14.6663 7 14.6663L25.6667 14.6663C26.403 14.6663 27 15.2633 27 15.9997Z"
        fill={props?.fill || '#0FD2BE'}
      />
    </svg>
  );
};

export { AnyLess };

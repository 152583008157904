import styled from 'styled-components';

export const Container = styled.section`
  padding: 80px 24px 40px;

  @media (min-width: 1024px) {
    padding: 120px 56px;
  }

  @media (min-width: 1440px) {
    padding: 120px 112px;
    max-width: 1440px;
    margin: 0px auto;
  }

  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }
`;

export const Title = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 48px;

  color: #005591;

  @media (min-width: 1024px) {
    margin-bottom: 64px;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
  }

  @media (min-width: 1440px) {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 70px;
  }
`;

export const ContentGrid = styled.section`
  display: flex;
  flex-direction: column;

  gap: 40px;

  @media (min-width: 1024px) {
    justify-content: center;
    flex-direction: row;

    gap: 24px;
  }

  @media (min-width: 1440px) {
    gap: 32px;
  }
`;

export const ContentCard = styled.section`
  box-shadow: -4px 4px 0px #e0e0e0;
  border-radius: 16px;

  @media (min-width: 1024px) {
    width: 288px;
  }

  @media (min-width: 1440px) {
    width: 384px;
    border: 1px solid #e0e0e0;
    border-radius: 24px;
  }
`;

export const CardImage = styled.section<{ src: string }>`
  border-radius: 16px 16px 0px 0px;
  height: 216px;
  width: 100%;

  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;

  @media (min-width: 1024px) {
    width: 288px;
  }

  @media (min-width: 1440px) {
    width: 384px;
  }
`;

export const CardContentText = styled.section`
  padding: 24px;

  @media (min-width: 1024px) {
    padding: 24px 20px;
  }

  @media (min-width: 1440px) {
    padding: 32px;
  }
`;

export const CardTitle = styled.h5`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;

  color: #0d0a0b;

  @media (min-width: 1440px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const CardDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  margin-bottom: 40px;

  color: #969696;

  @media (min-width: 1440px) {
    margin-bottom: 16px;
  }
`;

export const ButtonCard = styled.button`
  padding: 8px 16px;

  height: 35px;
  background: #008df1;
  box-shadow: -2px 2px 0px #005591;
  border-radius: 4px;

  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
  border: 0px;
`;

import styled from 'styled-components';

import BannerStory from 'assets/images/BannerStory.png';

export const Container = styled.section`
  background-color: #097e72;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;

    height: 440px;
  }

  @media (min-width: 1440px) {
    height: 520px;
    max-width: 1440px;
    margin: 0px auto;
  }
`;

export const ContentText = styled.section`
  display: flex;
  flex-direction: column;

  padding: 80px 24px;

  @media (min-width: 1024px) {
    align-items: flex-end;

    padding: 86px 56px 86px 48px;
    max-width: 538px;
  }

  @media (min-width: 1440px) {
    padding: 88.5px 112px 88.5px 72px;
    max-width: 840px;
  }

  button {
    width: 100%;
    height: 56px;

    @media (min-width: 1024px) {
      width: 320px;
      height: 71px;
    }
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 40px;

  color: #ffffff;

  @media (min-width: 1024px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    max-width: 432px;

    text-align: right;
  }

  @media (min-width: 1440px) {
    font-size: 48px;
    line-height: 58px;

    max-width: 656px;
  }
`;

export const ImageBackground = styled.section`
  display: none;

  background-image: url(${BannerStory});
  background-position: center;
  background-size: cover;

  @media (min-width: 1024px) {
    display: block;

    width: 541.45px;
    height: 440px;
  }

  @media (min-width: 1440px) {
    width: 600px;
    height: 520px;
  }
`;

export const MaxContent = styled.section`
  @media (max-width: 1023px) {
    max-width: 600px;
    margin: 0px auto;
  }

  @media (min-width: 1024px) {
    display: flex;
    width: 100%;
    justify-content: space-between;

    height: 440px;
  }
`;
